import React, {Component} from 'react';

import TextField, {TextFieldProps} from '@material-ui/core/TextField';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {withStyles} from "@material-ui/core/styles";
import {KEY_CODES} from "amn/common/Constants";
import clx from "clsx";
import {BoxProps} from "@material-ui/core/Box/Box";

type Props = {
  value: string;
  onSubmit: (value: string)=> void;
  textProperties?: TypographyProps | BoxProps;
  textFieldProperties?: TextFieldProps;
  TextComponent?: React.Component;
  placeholder?: string;
  keepEditButtonShown?: boolean;
};

type State = {
  editMode: boolean;
  innerValue: string;
};

class InlineEditableTextField extends Component<Props, State> {

  static defaultProps = {
    textProperties: {},
    textFieldProperties: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      innerValue: props.value
    };
  }

  onClick = (event) => {
    event.stopPropagation();
    this.setState({
      editMode: true,
    });

  }

  onBlur = () => {
    this.submit();
  }

  disableEditMode() {
    this.setState({editMode: false});
  }

  submit() {
    this.disableEditMode();
    const {onSubmit} = this.props;
    onSubmit && onSubmit(this.state.innerValue);
  }

  onKeyDown = (event) => {
    // if multiline, add new line on enter + shift
    if (event.keyCode === KEY_CODES.ENTER && !(this.props.textFieldProperties.multiline && event.shiftKey)) {
      this.submit();
    } else if (event.keyCode === KEY_CODES.ESC) {
      this.disableEditMode();
    }
  }

  onChange = (event) => {
    this.setState({
      innerValue: event.target.value
    });
  }

  onFocus =(event) => {
    const input = event.target;
    const length = input.value?.length;
    input.setSelectionRange(length, length)
  }

  render() {
    const {editMode, innerValue} = this.state;
    const {classes, textFieldProperties, keepEditButtonShown} = this.props;
    if (editMode) {
      const textFieldProps = {
        ...textFieldProperties,
        InputProps: {
          ...(textFieldProperties.InputProps || {}),
          className: clx(textFieldProperties.InputProps?.className, classes.input)
        }
      };
      return (
        <TextField {...textFieldProps}
                   value={innerValue}
                   autoFocus
                   multiline
                   onFocus={this.onFocus}
                   onChange={this.onChange}
                   onKeyDown={this.onKeyDown}
                   onBlur={this.onBlur}
                   onClick={this.onClick}/>
      )
    }
    return (
      <Box display="flex"
           position="relative"
           alignItems="center"
           minHeight={32}
           onClick={this.onClick}
           className={classes.text}>
        {this.renderText()}
        <Box display="flex"
             justifyContent="center"
             position="absolute"
             alignItems="center"
             width={30}
             height={30}
             className={clx(classes.editButton, {[classes.editButtonVisible]: keepEditButtonShown})}>
          <EditOutlinedIcon fontSize="small" color="secondary"/>
        </Box>
      </Box>
    );
  }

  renderText() {
    const {textProperties, textFieldProperties, value, TextComponent, placeholder} = this.props;
    if (TextComponent) {
      return (
        <Box flexGrow={1}
             color={value ? "text.primary" : "text.secondary"}
             mr={3}>
          {
            <TextComponent {...textProperties}>
              {value || placeholder || ""}
            </TextComponent>
          }
        </Box>
      );
    }
    return (
      <Box flexGrow={1}
           pl={1}
           mr={3}>
        {textFieldProperties.multiline &&
          <pre style={{fontFamily: 'inherit', margin: 0, whiteSpace: "pre-wrap", overflowWrap: "anywhere"}}>
              {value || ""}
            </pre>
        }
        {!textFieldProperties.multiline &&
          <Typography {...textProperties} >
            <span>{value || ""}</span>
          </Typography>
        }
      </Box>
    );
  }
}

const styles = (theme) => ({

  input: {
    font: "inherit"
  },
  text: {
    '&:hover $editButton': {
      display: "flex",
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius
    },
    cursor: "pointer"
  },

  editButton: {
    display: "none",
    borderRadius: "50%",
    background: "white",
    right: "4px",
    top: 0
  },
  editButtonVisible: {
    display: "flex"
  }
});

export default withStyles(styles)(InlineEditableTextField);



