export const ACCOUNT_BASE_URL = `/account`;
export const PRODUCTS_BASE_URL = `/products`;

export const ACCOUNT_SUBSCRIPTIONS_RELATIVE_URL = `subscriptions`;
export const ACCOUNT_LICENSES_RELATIVE_URL = `licenses`;
export const PRODUCT_AMN_AUDITOR_RELATIVE_URL = `amn-auditor`;
export const SUCCESS_RELATIVE_URL = `success`;


const PortalPaths = {

  accountUrl: () => {
    return `${ACCOUNT_BASE_URL}`;
  },

  accountLicensesUrl: () => {
    return `${ACCOUNT_BASE_URL}/${ACCOUNT_LICENSES_RELATIVE_URL}`;
  },

  amnAuditorLicenseCreationSuccessUrl: (licenseId: string) => {
    return `${PRODUCTS_BASE_URL}/${PRODUCT_AMN_AUDITOR_RELATIVE_URL}/${SUCCESS_RELATIVE_URL}/${licenseId}`;
  }

};
export default PortalPaths
