import React from "react";
import {Redirect, Route} from "react-router-dom";
import IfFirebaseAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseAuthed";
import IfFirebaseUnAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseUnAuthed";

const PrivateRoute = ({component: Comp, loginPath = "/login", ...props}) => {
  return (
    <Route
      {...props}
      render={
        (routeProps) => (
          <>
            <IfFirebaseAuthed>
              <Comp {...routeProps} />
            </IfFirebaseAuthed>
            <IfFirebaseUnAuthed>
              <Redirect to={{
                pathname: loginPath,
                state: {from: props.location},
              }}/>
            </IfFirebaseUnAuthed>
          </>
        )
      }
    />
  )
}

export default PrivateRoute;

