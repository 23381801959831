import {BoxProps} from "@material-ui/core/Box/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import Box from "@material-ui/core/Box";


type Props = {
  loading: boolean;
  minHeight?: number;
} & BoxProps;

const LoadingContainer = ({loading, minHeight = 400, children, ...boxProps}: Props) => {
  if (loading) {
    return (
      <Box minHeight={minHeight}
           display="flex"
           alignItems="center"
           justifyContent="center"
           {...boxProps} >
        <CircularProgress/>
      </Box>
    )
  } else {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  }
}

export default LoadingContainer;
