import type {PersistentEntity} from "../common/Entity";
import memoize from "lodash/memoize";
import type {GenericOption} from "amn/ui/forms/GenericOption";
import {getOptionByValue, getOptionLabelByValue} from "amn/ui/forms/GenericOption";
import SubscriptionStatusOptions from "./SubscriptionStatus.json";


export const SUBSCRIPTION_STATUS_FUTURE = "future";
export const SUBSCRIPTION_STATUS_IN_TRIAL = "in_trial";
export const SUBSCRIPTION_STATUS_ACTIVE = "active";
export const SUBSCRIPTION_STATUS_NON_RENEWING = "non_renewing";
export const SUBSCRIPTION_STATUS_PAUSED = "paused";
export const SUBSCRIPTION_STATUS_CANCELLED = "cancelled";


export type SubscriptionStatus = SUBSCRIPTION_STATUS_FUTURE |
  SUBSCRIPTION_STATUS_IN_TRIAL |
  SUBSCRIPTION_STATUS_ACTIVE |
  SUBSCRIPTION_STATUS_NON_RENEWING |
  SUBSCRIPTION_STATUS_PAUSED |
  SUBSCRIPTION_STATUS_CANCELLED;

export const SUBSCRIPTION_BILLING_PERIOD_UNIT_MONTH = "month";
export const SUBSCRIPTION_BILLING_PERIOD_UNIT_YEAR = "year";
export type SubscriptionBillingPeriodUnit =
  SUBSCRIPTION_BILLING_PERIOD_UNIT_MONTH
  | SUBSCRIPTION_BILLING_PERIOD_UNIT_YEAR;

export type SubscriptionStatusOption = GenericOption<SubscriptionStatus>;

export const SUBSCRIPTION_STATUS_OPTIONS
  : SubscriptionStatusOption[]
  = SubscriptionStatusOptions;


export const getSubscriptionStatusOption = memoize((status: SubscriptionStatus): SubscriptionStatusOption => {
  return getOptionByValue(status, SubscriptionStatusOptions);
});


export const getSubscriptionStatusLabel = (v: SubscriptionStatus, language): string => {
  return getOptionLabelByValue(v, SubscriptionStatusOptions, language);
};


export const SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY = "AABM";
export const SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY = "AABY";
export const SUBSCRIPTION_PLAN_AMN_AUDITOR_PLANS = [
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY,
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY
]
export type SubscriptionPlan = SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY
  | SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY;


export type SubscriptionItem = {
  itemPriceId: string,
  amount: number,
  unitPrice: number,
  trialEndDate: string,
  // item_type: "plan",
  // quantity: 1,
  // free_quantity: 0,
};


export type Subscription = {

  subscriptionItems: SubscriptionItem[];
  customerId: string;
  status: SubscriptionStatus;

  paymentSourceId?: string;
  billingPeriod: number;
  billingPeriodUnit: SubscriptionBillingPeriodUnit;
  currencyCode: "EUR" | "USD";

  hasScheduledChanges: boolean;
  deleted: boolean;

  startDate: string;
  currentTermEndDate: string;
  nextBillingDate: string;
  trialStartDate: string;
  trialEndDate: string;
  // due_invoices_count: 0
} & PersistentEntity;

export type CreateSubscriptionResponse = {
  subscription: Subscription;
  license: License;
}

//*******************************************************
// License

export const LICENSE_STATUS_ACTIVE = "ACTIVE";
export const LICENSE_STATUS_INACTIVE = "INACTIVE";
export const LICENSE_STATUS_EXPIRING = "EXPIRING";
export const LICENSE_STATUS_EXPIRED = "EXPIRED";
export const LICENSE_STATUS_SUSPENDED = "SUSPENDED";
export const LICENSE_STATUS_BANNED = "BANNED";

export type LicenseStatus = LICENSE_STATUS_ACTIVE |
  LICENSE_STATUS_INACTIVE |
  LICENSE_STATUS_EXPIRING |
  LICENSE_STATUS_EXPIRED |
  LICENSE_STATUS_SUSPENDED |
  LICENSE_STATUS_BANNED;

export type LicenseStatusOption = GenericOption<LicenseStatus>;

export type License = {
  subscriptionId: string;
  activationToken: string;
  customerId: string;
  name: string;
  expiryDate: string;
  status: LicenseStatus;
} & PersistentEntity;


//*******************************************************
// CUSTOMER INFO

export type Address = {
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  line1: string;
  state: string;
  stateCode: string;
  validationStatus: string;
  zip: string;
};


export type CustomerInfo = {
  billing_address: Address;
  cardStatus: string;
  locale: string;
  primaryPaymentSourceId: string;
  backupPaymentSourceId: string;
  // "allow_direct_debit": false,
  // "auto_collection": "on",
  // "created_at": 1517505731,
  // "deleted": false,
  // "email": "john@test.com",
  // "excess_payments": 0,
  // "first_name": "John",
  // "id": "__test__KyVnHhSBWl7eY2bl",
  // "last_name": "Doe",
  // "net_term_days": 0,
  // "object": "customer",
  // "pii_cleared": "active",
  // "preferred_currency_code": "USD",
  // "promotional_credits": 0,
  // "refundable_credits": 0,
  // "resource_version": 1517505731000,
  // "taxability": "taxable",
  // "unbilled_charges": 0,
  // "updated_at": 1517505731
} & PersistentEntity;


//*******************************************************
// functions
//

export const renderSubscriptionPrice = (subscription: Subscription) => {
  if (!subscription) return;
  return `${subscription.subscriptionItems[0].amount / 100} ${subscription.currencyCode}`;
}

