import firebase from "amn/common/firebase/index"
import type {User} from "amn/model/account/User";


class FirebaseAuth {

  getCurrentUser() {
    return firebase.auth().currentUser;
  }

  async getCurrentUserIdToken() {
    return firebase.auth().currentUser.getIdToken();
  }

  getCurrentUserId() {
    const currentUser = this.getCurrentUser();
    return currentUser?.uid;
  }

  async updateCurrentUser(user: User) {
    if (!firebase.auth().currentUser) return;
    await firebase.auth().currentUser.updateProfile({
      displayName: user.name,
      photoURL: user.picture,
    });
  }

  async signOut() {
    await firebase.auth().signOut();
  }

  addAuthStateListener(listener) {
    return firebase.auth().onAuthStateChanged(listener);
  }

  async signInWithCustomToken(token): Promise<firebase.auth.UserCredential> {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return firebase.auth().signInWithCustomToken(token);
  }
}


const firebaseAuth = new FirebaseAuth();

export default firebaseAuth;
