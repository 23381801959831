import type {User} from "amn/model/account/User";
import firebaseAuth from "amn/common/firebase/auth/FirebaseAuth";
import {createOrUpdateUser, CreateOrUpdateUserAction, getMe, GetMeAction} from "../../actions/iam/user.actions";
import store from "../../index";
import {DATA_LOADED} from "../../reducers/common";
import {selectUser, selectUserDataStatus} from "../../selectors/iam/user.selectors";


const IAMManager = {

  init: () => {
    IAMManager.initUser();
    firebaseAuth.addAuthStateListener((user) => {
      if (user) {
        IAMManager.initUser();
      }
    });
  },

  initUser: async (): GetMeAction => {
    const dataStatus = selectUserDataStatus(store.getState());
    if (dataStatus !== DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return getMe();
    } else {
      const user = selectUser(store.getState());
      return Promise.resolve({success: user});
    }
  },


  updateCurrentUser: async (user: User): CreateOrUpdateUserAction => {
    return createOrUpdateUser(user);
  },

}

export default IAMManager;
