import React, {Suspense} from "react";
import Box from "@material-ui/core/Box";
import {SvgIconProps} from "@material-ui/core/SvgIcon";

export const SECURITY_WARNING_ICON = "SECURITY_WARNING";

export const PRIORITY_LOW_ICON = "PRIORITY_LOW";
export const PRIORITY_MEDIUM_ICON = "PRIORITY_MEDIUM";
export const PRIORITY_HIGH_ICON = "PRIORITY_HIGH";

export const COMPLEXITY_LOW_ICON = "COMPLEXITY_LOW";
export const COMPLEXITY_MEDIUM_ICON = "COMPLEXITY_MEDIUM";
export const COMPLEXITY_HIGH_ICON = "COMPLEXITY_HIGH";

export const IMPORTANCE_LOW_ICON = "IMPORTANCE_LOW";
export const IMPORTANCE_MEDIUM_ICON = "IMPORTANCE_MEDIUM";
export const IMPORTANCE_HIGH_ICON = "IMPORTANCE_HIGH";

export const ASSIGNMENT_PARTIAL_ICON = "ASSIGNMENT_PARTIAL";
export const ASSIGNMENT_MOSTLY_ICON = "ASSIGNMENT_MOSTLY";
export const ASSIGNMENT_NA_ICON = "ASSIGNMENT_NA";
export const ASSIGNMENT_OK_ICON = "ASSIGNMENT_OK";
export const ASSIGNMENT_WARNING_ICON = "ASSIGNMENT_WARNING";
export const ASSIGNMENT_NOT_DONE_ICON = "ASSIGNMENT_NOT_DONE";

export const SUBSCRIPTION_FUTURE = "SUBSCRIPTION_FUTURE";
export const SUBSCRIPTION_IN_TRIAL = "SUBSCRIPTION_IN_TRIAL";
export const SUBSCRIPTION_ACTIVE = "SUBSCRIPTION_ACTIVE";
export const SUBSCRIPTION_NON_RENEWING = "SUBSCRIPTION_NON_RENEWING";
export const SUBSCRIPTION_PAUSED = "SUBSCRIPTION_PAUSED";
export const SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED";


export const NA_ICON = "NA_ICON";
export const CIRCLE_PROGRESS_0_D_ICON = "CIRCLE_PROGRESS_0_D_ICON";
export const CIRCLE_PROGRESS_90_D_ICON = "CIRCLE_PROGRESS_90_D_ICON";
export const CIRCLE_PROGRESS_300_D_ICON = "CIRCLE_PROGRESS_300_D_ICON";
export const CIRCLE_PROGRESS_360_D_ICON = "CIRCLE_PROGRESS_360_D_ICON";

export const ACCESS_ALARM_ICON = "ACCESS_ALARM_ICON";

export const TASK_IN_PROGRESS_ICON = "TASK_IN_PROGRESS";
export const TASK_WAITING_ICON = "TASK_WAITING";
export const TASK_DONE_ICON = "TASK_DONE";
export const TASK_NA_ICON = "TASK_NA";
export const TASK_TODO_ICON = "TASK_TODO";


export const DYNAMIC_ICONS = {
  [SECURITY_WARNING_ICON]: React.lazy(() => import("./SecurityWarningIcon")),

  [PRIORITY_LOW_ICON]: React.lazy(() => import("./PriorityLowIcon")),
  [PRIORITY_MEDIUM_ICON]: React.lazy(() => import("./PriorityMediumIcon")),
  [PRIORITY_HIGH_ICON]: React.lazy(() => import("./PriorityHighIcon")),

  [COMPLEXITY_LOW_ICON]: React.lazy(() => import("@material-ui/icons/StopOutlined")),
  [COMPLEXITY_MEDIUM_ICON]: React.lazy(() => import("./ComplexityMediumIcon")),
  [COMPLEXITY_HIGH_ICON]: React.lazy(() => import("./ComplexityHighIcon")),

  [IMPORTANCE_LOW_ICON]: React.lazy(() => import("./ImportanceLowIcon")),
  [IMPORTANCE_MEDIUM_ICON]: React.lazy(() => import("./ImportanceMediumIcon")),
  [IMPORTANCE_HIGH_ICON]: React.lazy(() => import("@material-ui/icons/ErrorOutlineOutlined")),

  [TASK_IN_PROGRESS_ICON]: React.lazy(() => import("./CircleProgress90dIcon")),
  [TASK_WAITING_ICON]: React.lazy(() => import("./TaskWaitingIcon")),
  [TASK_DONE_ICON]: React.lazy(() => import("@material-ui/icons/RadioButtonCheckedOutlined")),
  [TASK_NA_ICON]: React.lazy(() => import("./TaskNAIcon")),
  [TASK_TODO_ICON]: React.lazy(() => import("./TaskTodoIcon")),

  [ASSIGNMENT_OK_ICON]: React.lazy(() => import("@material-ui/icons/AssignmentTurnedInOutlined")),
  [ASSIGNMENT_WARNING_ICON]: React.lazy(() => import("@material-ui/icons/AssignmentLateOutlined")),
  [ASSIGNMENT_PARTIAL_ICON]: React.lazy(() => import("./AssignmentPartiallyDoneIcon")),
  [ASSIGNMENT_MOSTLY_ICON]: React.lazy(() => import("./AssignmentMostlyDoneIcon")),
  [ASSIGNMENT_NA_ICON]: React.lazy(() => import("./AssignmentNAIcon")),
  [ASSIGNMENT_NOT_DONE_ICON]: React.lazy(() => import("./AssignmentNotDoneIcon")),

  [SUBSCRIPTION_FUTURE]: React.lazy(() => import("@material-ui/icons/ScheduleOutlined")),
  [SUBSCRIPTION_IN_TRIAL]: React.lazy(() => import("@material-ui/icons/TimerOutlined")),
  [SUBSCRIPTION_ACTIVE]: React.lazy(() => import("@material-ui/icons/CheckCircleOutlineOutlined")),
  [SUBSCRIPTION_NON_RENEWING]: React.lazy(() => import("@material-ui/icons/AlarmOffOutlined")),
  [SUBSCRIPTION_PAUSED]: React.lazy(() => import("@material-ui/icons/PauseCircleOutlineOutlined")),
  [SUBSCRIPTION_CANCELLED]: React.lazy(() => import("@material-ui/icons/NotInterestedOutlined")),


  [NA_ICON]: React.lazy(() => import("./NAIcon")),
  [CIRCLE_PROGRESS_90_D_ICON]: React.lazy(() => import("./CircleProgress90dIcon")),
  [CIRCLE_PROGRESS_300_D_ICON]: React.lazy(() => import("./CircleProgress300dIcon")),
  [ACCESS_ALARM_ICON]: React.lazy(() => import("@material-ui/icons/AccessAlarmOutlined")),
  [CIRCLE_PROGRESS_360_D_ICON]: React.lazy(() => import("@material-ui/icons/FiberManualRecord")),
  [CIRCLE_PROGRESS_0_D_ICON]: React.lazy(() => import("@material-ui/icons/FiberManualRecordOutlined")),

}


type Props = { iconId: string } & SvgIconProps;

export const DynamicIcon = ({iconId, ...props}: Props) => {

  if (DYNAMIC_ICONS[iconId]) {
    const IconComponent = DYNAMIC_ICONS[iconId];
    return (
      <Suspense fallback={<Box/>}>
        <IconComponent fontSize="small" {...props}/>
      </Suspense>
    )
  } else {
    return (
      <Box display="flex"
           width={24}
           justifyContent="center"
           alignItems="center">
        -
      </Box>
    )
  }
};


