import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import ElevationScroll from "./ElevationScroll";
import {NavLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import ButtonSignIn from "./ButtonSignIn";
import DrawerAppBar from "./DrawerAppBar";

type Props = {};
type State = {};

class PrimaryAppBar extends Component<Props, State> {


  render() {
    const {classes} = this.props;
    return (
      <Box flexGrow={1}>

        <ElevationScroll>
          {(trigger) => (
            <AppBar position="fixed"
                    elevation={trigger ? 3 : 0}
                    className={classes.appBar}>
              <Toolbar>
                <Box>
                  <Link color="inherit" component={NavLink}
                        to={"/"}>
                    <img src={require("assets/images/logo/amn-brains-chars.png")}
                         alt={"Amn Brains Logo"}
                         className={classes.logo}/>
                  </Link>
                </Box>
                <Box mr={2} ml={2} flex={1}>
                  {/*<PrimaryAppBarSearchControl/>*/}
                </Box>

                <Hidden smDown>

                  <ButtonSignIn/>
                </Hidden>
                <Hidden mdUp>
                  <DrawerAppBar/>
                </Hidden>
              </Toolbar>
            </AppBar>
          )}
        </ElevationScroll>
      </Box>
    );
  }

}


const styles = (theme) => ({

  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  logo: {
    width: "160px",
    margin: "4px"
  },
  button: {
    // color : theme.palette.primary.contrastText
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
})

export default withStyles(styles)(PrimaryAppBar);



