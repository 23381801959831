import UsersOperations from "../firebase/store/UserOperations";
import type {User} from "amn/model/account/User";


const UserBackendAdapter = {

  getMe: (): Promise<User> => {
    return UsersOperations.getMe();
  },

}

export default UserBackendAdapter;
