import React from 'react';
import cx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles, {SidebarClassKey} from './SidebarItem.styles';
import Tooltip from "@material-ui/core/Tooltip";

export type SidebarItemProps = {
  startIcon: React.ReactElement;
  label: React.ReactNode;
  tooltip?: React.ReactNode;
  amount?: React.ReactNode;
  collapsed?: boolean;
  dotOnCollapsed?: boolean;
  selected?: boolean;
  color?: string;
  classes?: Partial<Record<SidebarClassKey, string>>;
  className?: string;
};

const useStyles = makeStyles(styles, {name: 'SidebarItem'});

const SidebarItem = ({
                       color,
                       startIcon,
                       label,
                       amount,
                       classes = {},
                       collapsed,
                       dotOnCollapsed,
                       selected,
                       tooltip,
                       ...buttonProps
                     }: SidebarItemProps) => {
  const styles = useStyles({classes, color});

  return (
    <Tooltip title={tooltip || label}>
      <ButtonBase {...buttonProps}
                  activeClassName={styles.selected}
                  classes={{
                    ...buttonProps.classes,
                    root: cx(styles.root, {
                      [styles.collapsed]: collapsed,
                      [styles.dot]: collapsed && dotOnCollapsed && amount,
                    }),
                    focusVisible: styles.focusVisible,
                  }}>

        <div className={'SidebarItem-startIcon'}>{startIcon}</div>
        <div className={'SidebarItem-label'}>{label}</div>
        {amount && (
          <div className={'SidebarItem-amount'}>
            {amount}
          </div>
        )}
      </ButtonBase>
    </Tooltip>
  );
};

export default SidebarItem;
