import React from "react";
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./modules/common/routes/PrivateRoute";
import LoginScreen from "./modules/iam/login/screens/LoginScreen";
import AmnAuditorSignupScreen from "./modules/portal/amauditor/screens/AmnAuditorSignupScreen";
import AmnAuditorSignupCompleteScreen from "./modules/portal/amauditor/screens/AmnAuditorSignupCompleteScreen";
import AmnAuditorSuccessScreen from "./modules/portal/amauditor/screens/AmnAuditorSuccessScreen";
import PortalScreen from "./modules/portal/screen/PortalScreen";
import LogoutScreen from "./modules/iam/login/screens/LogoutScreen";


export default function Routes() {

  return (
    <Switch>
      <Route exact path="/login" component={LoginScreen}/>
      <Route exact path="/logout" component={LogoutScreen}/>
      <Route exact path="/products/amn-auditor/signup" component={AmnAuditorSignupScreen}/>
      <PrivateRoute exact
                    loginPath="/products/amn-auditor/signup"
                    path="/products/amn-auditor/trial"
                    component={AmnAuditorSignupCompleteScreen}/>
      <PrivateRoute exact path="/products/amn-auditor/success/:licenseId" component={AmnAuditorSuccessScreen}/>
      <PrivateRoute path="/" component={PortalScreen}/>
    </Switch>
  );
}
