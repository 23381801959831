import firebaseAuth from "./FirebaseAuth";
import Config from "amn/Config";
import {Auth0Lock} from "auth0-lock";
import type {User} from "amn/model/account/User";
import firebase from "firebase";


export const signInWithCustomToken = async (idToken): Promise<firebase.auth.UserCredential> => {
  const response = await fetch('/api/auth/', {
    headers: {Authorization: `Bearer ${idToken}`}
  });

  const data = await response.json();
  return firebaseAuth.signInWithCustomToken(data.firebaseToken);
}


export const updateFirebaseCurrentUser = async (user: User) => {
  return await firebaseAuth.updateCurrentUser(user);
}

export const signOut = async () => {
  const auth0Lock = new Auth0Lock(
    Config.AUTH0_CLIENT_ID,
    Config.AUTH0_DOMAIN,
  );

  await firebaseAuth.signOut();
  auth0Lock.logout({returnTo: window.location.origin});
}
