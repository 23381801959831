import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import {AccountBreadcrumbs, AccountSubscriptionsListBreadcrumb} from "../../common/breadcumbs";
import ScreenTitle from "../../../common/components/heads/ScreenTitle";
import SubscriptionsList from "../components/licenses/AccountLicensesList";
import AccountLicensesAddNewButton from "../components/licenses/AccountLicensesAddNewButton";


class AccountLicensesScreen extends Component<Props> {


  render() {
    return (
      <>
        <Box mb={2}>
          <AccountBreadcrumbs>
            <AccountSubscriptionsListBreadcrumb active/>
          </AccountBreadcrumbs>
        </Box>
        <ScreenTitle renderActions={() => <Box><AccountLicensesAddNewButton/></Box>}>

          <Box fontSize="subtitle1.fontSize">Licences et Abonnements</Box>
        </ScreenTitle>
        <SubscriptionsList/>
      </>
    );
  }


}

export default AccountLicensesScreen;