import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

type Props = {
  title: React.ReactNode,
  initiallyOpen?: boolean,
  children?: React.ReactNode,
  unmountOnExit?: boolean,
  buttonSize?: string;
  getTooltipTitle?: (open: boolean)=>React.ReactNode;
};


const CollapsiblePanel = ({
                            title,
                            initiallyOpen = true,
                            buttonSize,
                            children,
                            unmountOnExit = true,
                            getTooltipTitle = () => "Expand/Collapse"
                          }: Props) => {
  const [open, setOpen] = useState(initiallyOpen);
  return (
    <Box>
      <Box alignItems="center"
           display="flex">
        <Box flexGrow={1}>
          {title}
        </Box>
        <Box justifyContent="center"
             mr={1}
             alignItems="center"
             display="flex">
          <Tooltip title={getTooltipTitle(open)}>
            <IconButton size={buttonSize} onClick={() => setOpen(!open)}>
              {open ? <ExpandLess fontSize={buttonSize}/> : <ExpandMore fontSize={buttonSize}/>}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit={unmountOnExit}>
        {children}
      </Collapse>
    </Box>
  );
}


export default CollapsiblePanel;



