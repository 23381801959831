import reduce from "lodash/reduce";
import {getEntityId} from "./Entity";

export const TYPE_DISCRIMINATOR = "@type";

// export

export type LookupTable<T> = { [key: string]: T };
export type LookupFn<T> = (string)=>T;

export const listToLookupTable = (list, getElementId = getEntityId) => {
  return reduce(list, (table, item) => {
    return {
      ...table,
      [getElementId(item)]: item
    };
  }, {});
};


