import React from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import {NavLink} from "react-router-dom";


export const ActiveBreadcrumb = ({icon, label}) => (
  <Box display="flex"
       fontSize={"body2.fontSize"}
       color={"primary.main"}
       alignItems="center">
    {icon &&
      <Box mr={1}
           display="flex"
           alignItems="center">
        {icon}
      </Box>
    }
    {label}
  </Box>
);

export const LinkBreadcrumb = ({to, icon, label, onClick}) => (
  <Link color="inherit"
        onClick={onClick}
        component={NavLink}
        to={to}>
    <Box display="flex"
         fontSize={"body2.fontSize"}
         alignItems="center">
      <Box mr={1}
           display="flex"
           alignItems="center">
        {icon}
      </Box>
      {label}
    </Box>
  </Link>
);

export const Breadcrumb = ({to, icon, label, active, onClick}) => (
  <>
    {active && <ActiveBreadcrumb icon={icon} label={label}/>}
    {!active && <LinkBreadcrumb to={to} icon={icon} label={label} onClick={onClick}/>}
  </>
)
