import "fontsource-montserrat/500.css";
import "fontsource-montserrat/600.css";
import "fontsource-montserrat/700.css";
import "fontsource-montserrat/800.css";
import "fontsource-montserrat/400.css";

import get from "lodash/get";

export const borderColor = "#e3e6ed";
export const mainColor = '#109CF1';
// const successColor = "#3cae96";
const successColor = "#2ed47a";
const errorColorPalette = {
  contrastText: "#FFFFFF",
  main: "#F7685B",
  dark: "#d64336",
  bg: "#FFF8F8",
};

const text = {
  primary: '#192A3E',
  secondary: '#8497b3',
  disabled: '#b2becd'
};

const placeholder = {
  color: text.secondary,
  opacity: 1,
  fontSize: 14
};

// A custom theme for this app

export const palette = {
  primary: {
    main: mainColor,
    contrastText: "#FFFFFF",
    bg: "#f3faff",
    selected: "#aadbfa",
    selectedHover: "#89c8f1",
  },
  secondary: {
    main: '#054582',
    contrastText: "#FFFFFF",
    bg: "#f2f9ff"
  },
  background: {
    default: '#F5F6F8',
    // default: '#F6F9FF',
  },
  error: errorColorPalette,
  warning: {
    // main: "#E87409",
    main: "#FFB946",
    dark: "#ec9403",
    bg: "#FFFAF8"
  },
  success: {
    main: successColor,
    bg: "#f5fffa",
  },
  text: text,
  divider: borderColor,
  inputOutline: borderColor,
  // error: {
  //   main: red.A400,
  // },
  // background: {
  //   default: '#fff',
  // },
  action: {
    active: "#60738a",
    hover: "#f3f4f7",
    // hover_: "rgba(0, 0, 0, 0.04)",
    selected: "#e8ecf1",
    // selected: "rgba(0, 0, 0, 0.08)",
    // disabled: "#aab8c2",
    disabled: "#b2becd",
    // disabled_: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "#dde4ea",
    // disabledBackground_: "rgba(0, 0, 0, 0.12)",
    focus: "#dde4ea",
  }
};
const theme = {
  palette,
  typography: {
    fontFamily: ["Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"],
    subtitle1: {
      fontWeight: 500
    }
  },
  overrides: {
    MuiStepContent: {
      root: {
        borderLeft: "1px solid " + borderColor
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid " + borderColor,
        padding: "8px"
      },
      head: {
        padding: "16px",
        paddingLeft: "8px"
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        // backgroundColor: "#f9f9f9"
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        // backgroundColor: "#f9f9f9"
      }
    },
    MuiOutlinedInput: {
      root: {
        '@media (hover: none)': {
          '&:hover $notchedOutline': {
            borderColor
          }
        },
        '&:hover $notchedOutline': {
          borderColor: "#76ceff"
        },
        // backgroundColor: "#f5f6f878"
      },
      notchedOutline: {
        borderColor,
        // borderWidth: 2
      }
    },
    MuiInputBase: {
      root: {
        lineHeight: "inherit",
      },
      input: {
        '&::-webkit-input-placeholder': placeholder,
        '&::-moz-placeholder': placeholder,
        // Firefox 19+
        '&:-ms-input-placeholder': placeholder,
        // IE 11
        '&::-ms-input-placeholder': placeholder,
      }
    },
    MuiRadio: {
      root: {
        // color: mainColor
      }
    },
    MuiButtonGroup: {
      groupedTextHorizontal: {
        '&:not(:last-child)': {
          borderRightWidth: "0 !important"
        }
      },
      grouped: {
        minWidth: 22
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "#e8ecf1"
      }
    },
    MuiAlert: {
      '&.wide': {
        message: {
          flex: 1
        }
      }
    },
    MuiAccordion: {
      root: {
        boxShadow: "none"
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        backgroundColor: "#FFF"
      }
    },
    MuiButton: {
      contained: {
        boxShadow: "initial",
        '&:hover': {
          boxShadow: "initial",
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            boxShadow: "initial",
          }
        },
        '&$focusVisible': {
          boxShadow: "initial"
        },
        '&:active': {
          boxShadow: "initial"
        },
        '&$disabled': {
          boxShadow: "initial"
        }
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: "#b2becd",
        opacity: 1
      }
    }
  }
};

export default theme;


export const getPaletteColor = (path) => {
  return get(theme, "palette." + path)
}


