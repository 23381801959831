import client from "../common/RestAuthClient";
import type {License} from "amn/model/account/Subscription";


const LicensesApi = {

  getAccountLicenses: async () => {
    return client.get("/api/licenses");
  },

  patchLicense: async (licenseId: string, patch: Partial<License>) => {
    return client.patch(`/api/licenses/${licenseId}`, {data: patch});
  },

  sendLicenseInstructions: async (licenseId: string, email: string) => {
    return client.post(`/api/licenses/${licenseId}/actions/send-instructions`, {data: {email}});
  },
}


export default LicensesApi;