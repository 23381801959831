import React, {Component} from 'react';
import {withRouter} from "react-router";
import {WhiteBox} from "amn/ui/containers/Boxes";
import Box from "@material-ui/core/Box";
import type {History} from "history";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {withTranslation} from "react-i18next";
import {withSnackbar} from "notistack";
import AccountLicensesStartTrialButton from "./AccountLicensesStartTrialButton";
import type {SubscriptionPlan} from "amn/model/account/Subscription";


type Props = {
  history: History;
  plan: SubscriptionPlan;
};
type State = {};

class AccountLicensesEmptyList extends Component<Props, State> {


  render() {
    return (

      <WhiteBox display="flex" flexDirection="column" alignItems="center" p={4}>
        <Box mb={2} fontWeight="fontWeightMedium" color="secondary.main">
          Vous n'avez pas encore de licence ?
        </Box>
        <Box textAlign="center" color="secondary.main" fontSize="h5.fontSize" fontWeight="fontWeightBold">
          Démarrez votre essai <br/> <Box color="primary.main" component="span">Gratuitement</Box>
        </Box>
        <Box mt={4} mb={4}>
          <img src={require("assets/images/logo/amn-auditor.svg")}
               style={{height: 48}}
               alt="Amn Brains"/>
        </Box>
        <Box color="secondary.main">
          Logiciel desktop de conduite d'Audit cybersécurité
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <AccountLicensesStartTrialButton/>
        </Box>
      </WhiteBox>
    );
  }
}

export default withRouter(
  withSnackbar(
    withTranslation(I18N_NS_STANDARDS)(
      AccountLicensesEmptyList
    )
  )
);