import React, {useState} from 'react';
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import copy from "clipboard-copy";
import {IconButton, IconButtonProps, Snackbar} from "@material-ui/core";
import {SnackbarOrigin} from "@material-ui/core/Snackbar/Snackbar";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {Tooltip} from "./ToolTips";


type Props = {
  value: string;
  tooltip?: React.ReactNode;
  successMessage?: React.ReactNode;
  anchorOrigin: SnackbarOrigin;
} & IconButtonProps;

const CopyToClipboardIconButton = ({
                                     value,
                                     tooltip = "Copier",
                                     successMessage = "Copié",
                                     anchorOrigin,
                                     onClick,
                                     ...props
                                   }: Props) => {

  const [open, setOpen] = useState(false);

  const handleClick = async (event) => {
    if (value) {
      try {
        await copy(value);
        setOpen(true);
      } finally {

      }
    }
    onClick && onClick(event);
  }
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton {...props}
                    onClick={handleClick}>
          <FileCopyOutlinedIcon/>
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={successMessage}
      />
    </>
  );
};

export default CopyToClipboardIconButton;