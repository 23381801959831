import React from "react";
import Box from "@material-ui/core/Box";

import PrimaryAppBar from "./PrimaryAppBar";
import PrimaryFooter from "./PrimaryFooter";
import Container from "@material-ui/core/Container";


export default function PrimaryLayout({children}) {

  return (
    <div>
      <PrimaryAppBar/>
      <Box mt={12} minHeight={"60vh"}>
        <main>
          <Container>
            {children}
          </Container>
        </main>
      </Box>
      <Box mt={16}>
        <PrimaryFooter/>
      </Box>
    </div>
  );
}
