import React, {Component} from 'react';
import {connect} from "react-redux";
import type {DataStatus} from "amn/store/reducers/common";
import {DATA_LOADING} from "amn/store/reducers/common";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import {WhiteBox} from "amn/ui/containers/Boxes";
import type {LookupTable} from "amn/model/common/Divers";
import type {License} from "amn/model/account/Subscription";
import map from "lodash/map";
import {
  selectAccountLicenses,
  selectAccountLicensesDataStatus
} from "amn/store/selectors/account/account-licenses.selectors";
import AccountLicense from "./AccountLicense";
import isEmpty from "lodash/isEmpty";
import AccountLicensesEmptyList from "./AccountLicensesEmptyList";

type Props = {
  dataStatus: DataStatus;
  licenses: LookupTable<License>;
};

type State = {};


class AccountLicensesList extends Component<Props, State> {


  componentDidMount() {
    // AccountSubscriptionsManager.loadAccountSubscriptions();
    // AccountLicensesManager.loadAccountLicenses();
  }

  render() {

    const {dataStatus, licenses} = this.props;

    return (
      <LoadingContainer loading={dataStatus === DATA_LOADING}>
        {
          map(licenses, (license: License) => {
            return (
              <WhiteBox mt={2} key={license?.id}>
                <AccountLicense license={license}/>
              </WhiteBox>
            )
          })
        }
        {isEmpty(licenses) && <AccountLicensesEmptyList/>}


      </LoadingContainer>);
  }

  static mapStateToProps = (state) => {
    return {
      dataStatus: selectAccountLicensesDataStatus(state),
      licenses: selectAccountLicenses(state),
    }
  };
}


export default connect(AccountLicensesList.mapStateToProps)(AccountLicensesList);

