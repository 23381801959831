import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";


class AccountLicensesStartTrialButton extends Component {


  render() {

    return (
      <Button color="primary"
              component={NavLink}
              to="/products/amn-auditor/trial"
              variant="contained">
        Démarrer la période d'essai
      </Button>
    );
  }


}


export default AccountLicensesStartTrialButton;


