import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {WhiteBox} from "amn/ui/containers/Boxes";
import Link from "@material-ui/core/Link";
import Config from "amn/Config";
import Button from "@material-ui/core/Button";
import WindowsIcon from "amn/ui/icons/WindowsIcon";
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import {
  selectAccountLicenses,
  selectAccountLicensesDataStatus
} from "amn/store/selectors/account/account-licenses.selectors";
import {connect} from "react-redux";
import {DATA_LOADING} from "amn/store/reducers/common";
import isEmpty from "lodash/isEmpty";
import AccountLicensesEmptyList from "../../portal/account/components/licenses/AccountLicensesEmptyList";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import YoutubeList from "amn/ui/diverse/YoutubeList";
import {Tutorials} from "./Tutorials";
import size from "lodash/size";
import {NavLink} from "react-router-dom";
import PortalPaths from "../../common/routes/PortalPaths";
import ContractIcon from "../../../ui/icons/ContractIcon";


type Props = {};

type State = {
  drawerOpen: boolean;
};


class HomeScreen extends Component<Props, State> {


  render() {
    const {dataStatus, licenses} = this.props;
    return (
      <Box pt={7}>
        <Grid container spacing={3} alignItems="stretch">

          <Grid item xs={12} md={6} zeroMinWidth>
            <WhiteBox p={4} height={"100%"}>
              <LoadingContainer minHeight={80} loading={dataStatus === DATA_LOADING}>
                {isEmpty(licenses) &&
                  <AccountLicensesEmptyList/>
                }

                {!isEmpty(licenses) &&
                  <Box p={1} height="100%" display="flex" flexDirection="column">
                    <Box fontSize="h5.fontSize"
                         textAlign="center"
                         fontWeight="fontWeightMedium"
                         color="secondary.main">
                      Vos Licences
                    </Box>
                    <Box mt={2}  display="flex">
                      <Box flex={2} textAlign="center">
                        <Box fontSize="h1.fontSize"
                             color="primary.main"
                             fontWeight="fontWeightBold">
                          {size(licenses)}
                        </Box>
                        <Box mt={-1}>
                          Licences
                        </Box>
                        {/*<LicenseStatusRepartition width={160}/>*/}

                      </Box>
                      {/*<Box flex={1} position="relative">*/}

                      {/*  /!*<LicenseStatusRepartition width={160}/>*!/*/}

                      {/*</Box>*/}
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Button startIcon={<ContractIcon/>}
                              variant="outlined"
                              component={NavLink}
                              to={PortalPaths.accountLicensesUrl()}
                              color="secondary">
                        Liste des licences
                      </Button>
                    </Box>

                  </Box>
                }
              </LoadingContainer>
            </WhiteBox>
          </Grid>
          <Grid item xs={12} md={6} zeroMinWidth>
            {this.renderDownloadSection()}
          </Grid>


          <Grid item xs={12} zeroMinWidth>
            {this.renderVideosSection()}
          </Grid>

        </Grid>
      </Box>
    );
  }

  renderVideosSection = () => {
    return (
      <WhiteBox mt={2} p={6} pt={8} pb={8}>
        <Box mb={6} display="flex" justifyContent="center" color="secondary.main">
          <Box display="flex" alignItems="center">
            <div>
              <SubscriptionsOutlinedIcon fontSize="large"/>
            </div>
            <Box ml={1} fontSize="h4.fontSize"
                 fontWeight="fontWeightMedium">
              Découvrez Amn Auditor en Vidéo
            </Box>
          </Box>
        </Box>
        <div>
          <YoutubeList videosInfos={Tutorials}/>
        </div>
      </WhiteBox>
    )
  }

  renderDownloadSection = () => {
    return (
      <WhiteBox p={2} pt={8} pb={6} height="100%">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={2}>
            <img src={require("assets/images/logo/amn-auditor-cv.svg")}
                 style={{height: 88}}
                 alt="Amn Brains"/>
          </Box>
          <Box alignItems="center" display="flex" flex={1} ml={4} mb={4} color="secondary.main">
            <Box fontSize="subtitle1.fontSize" mt={2} textAlign="center">
              Télécharger l'installer <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold"
                                           component="span">Amn </Box><Box color="primary.main"
                                                                           fontWeight="fontWeightBold"
                                                                           fontSize="subtitle1.fontSize"
                                                                           component="span">Auditor</Box> pour
              Microsoft Windows. <br/>
              L'application existe également en version portable.
            </Box>
          </Box>
          <Box textAlign="center">
            <Box>
              <Button startIcon={<WindowsIcon/>}
                      variant="outlined"
                      component="a"
                      href={Config.AMN_AUDITOR.INSTALLER_LINK}
                      target="_blank"
                      color="secondary">
                Installateur Windows
              </Button>
            </Box>
            <Box mt={1} fontSize="caption.fontSize">
              <Link href={Config.AMN_AUDITOR.PORTABLE_LINK} target="_blank">Télécharger la version
                portable</Link>
            </Box>
          </Box>
        </Box>
      </WhiteBox>
    )
  }

  static mapStateToProps = (state) => {
    return {
      dataStatus: selectAccountLicensesDataStatus(state),
      licenses: selectAccountLicenses(state),
    }
  };
}


export default connect(HomeScreen.mapStateToProps)(HomeScreen);
