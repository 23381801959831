import React from 'react';
import {DynamicIcon} from "../icons";
import Box from "@material-ui/core/Box";
import type {GenericOption} from "./GenericOption";
import {SvgIconProps} from "@material-ui/core/SvgIcon";

type Props<T> = {
  option: GenericOption<T>;
  useOptionColor?: boolean;
} & SvgIconProps


function GenericOptionIcon<T>({option, useOptionColor = true, ...props}: Props<T>) {
  return (
    <Box color={useOptionColor ? option?.iconColor : undefined}
         display="flex"
         alignItems="center">
      <DynamicIcon iconId={option?.icon}
                   {...props}/>
    </Box>
  );
}


export default GenericOptionIcon;



