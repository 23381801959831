import {combineReducers} from 'redux';

import global from './global.reducer';
import settings from './settings.reducer';
import iamUI from './iam/iam-ui.reducer';
import user from './iam/user.reducer';
import subscriptions from './account/account-subscriptions.reducer';
import licenses from './account/account-licenses.reducer';
import customerInfo from './account/account-customer-info.reducer';

export default function createReducer(injectedReducers) {

  const reduce = combineReducers({
    settings,
    iam: combineReducers({
      ui: iamUI,
      user
    }),
    account: combineReducers({
      licenses,
      subscriptions,
      customerInfo,
    }),

    ...injectedReducers
  });
  return (state, action) => reduce(global(state, action), action);
}
