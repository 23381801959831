import React, {Component} from 'react';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import PrimaryLayout from "../../../common/components/layout/primary/PrimaryLayout";
import AccountLicenseCreationSuccess from "../../account/components/licenses/AccountLicenseCreationSuccess";

class AmnAuditorSuccessScreen extends Component {


  render() {
    const {match} = this.props;
    const {params} = match;
    return (
      <PrimaryLayout>
        <Box mt={4} mb={4}>
          <Container maxWidth="md">
            <AccountLicenseCreationSuccess licenseId={params.licenseId}/>
          </Container>
        </Box>
      </PrimaryLayout>
    );
  }
}

export default AmnAuditorSuccessScreen;