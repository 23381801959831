import React, {Component} from 'react';
import IfFirebaseAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseAuthed";
import {Auth0Lock} from "auth0-lock";
import Config from "../../../../Config";
import IfFirebaseUnAuthed from "../../../../common/firebase/auth/react/consumers/IfFirebaseUnAuthed";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebaseAuth from "../../../../common/firebase/auth/FirebaseAuth";

type Props = {};

class LogoutScreen extends Component<Props> {

  async componentDidMount() {
    const auth0Lock = new Auth0Lock(
      Config.AUTH0_CLIENT_ID,
      Config.AUTH0_DOMAIN,
    );

    await firebaseAuth.signOut();
    auth0Lock.logout({returnTo: window.location.origin});
  }


  render() {

    return (
      <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
        <IfFirebaseAuthed>
          <div>
            <Box textAlign="center">
              <CircularProgress/>
            </Box>
            <Box mt={4}>
              Déconnexion en cours
            </Box>

          </div>
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          <Box textAlign="center">
            <Box color={"primary.main"}>
              <svg viewBox="0 0 128 128" height={80} fill="#e3e6ed">
                <path
                  d="M 64 6.0371094 C 49.15 6.0496094 34.3 11.7 23 23 C 0.4 45.6 0.4 82.4 23 105 C 24.2 106.2 26.099219 106.2 27.199219 105 C 28.399219 103.8 28.399219 101.90078 27.199219 100.80078 C 6.9992188 80.500781 6.9992188 47.499219 27.199219 27.199219 C 47.499219 6.9992188 80.500781 6.9992188 100.80078 27.199219 C 121.10078 47.499219 121.10078 80.499219 100.80078 100.69922 C 99.600781 101.89922 99.600781 103.80039 100.80078 104.90039 C 102.00078 106.10039 103.9 106.10039 105 104.90039 C 116.3 93.600391 122 78.700391 122 63.900391 C 122 49.100391 116.3 34.200391 105 22.900391 C 93.7 11.650391 78.85 6.0246094 64 6.0371094 z M 64 61 A 3 3 0 0 0 61 64 A 3 3 0 0 0 64 67 A 3 3 0 0 0 67 64 A 3 3 0 0 0 64 61 z M 64 76 C 62.3 76 61 77.3 61 79 L 61 109.80078 L 51.099609 99.900391 C 49.899609 98.700391 48.000391 98.700391 46.900391 99.900391 C 46.300391 100.50039 46 101.3 46 102 C 46 102.7 46.300391 103.49961 46.900391 104.09961 L 61.900391 119.09961 C 62.500391 119.69961 63.3 120 64 120 C 64.8 120 65.499609 119.69961 66.099609 119.09961 L 81.099609 104.09961 C 82.299609 102.89961 82.299609 101.00039 81.099609 99.900391 C 79.899609 98.700391 78.100391 98.700391 76.900391 99.900391 L 67 109.80078 L 67 79 C 67 77.3 65.7 76 64 76 z"/>
              </svg>
            </Box>
            <Box mt={1}>
              Vous avez été déconnecté
            </Box>
            <Box mt={1}>
              Vous allez être redirigé
            </Box>
          </Box>
        </IfFirebaseUnAuthed>
      </Box>
    );
  }
}

export default LogoutScreen;