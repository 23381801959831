import {DATA_LOADED} from "../../reducers/common";
import store from "amn/store";
import firebaseAuth from "amn/common/firebase/auth/FirebaseAuth";
import {
  selectAccountLicenses,
  selectAccountLicensesDataStatus
} from "../../selectors/account/account-licenses.selectors";
import {loadAccountLicenses, LoadAccountLicensesAction} from "../../actions/account/account-licenses.actions";


const AccountLicensesManager = {

  init: () => {
    firebaseAuth.addAuthStateListener((user) => {
      if (user) {
        AccountLicensesManager.loadAccountLicenses();
      }
    });
  },

  loadAccountLicenses: (): LoadAccountLicensesAction => {
    const dataStatus = selectAccountLicensesDataStatus(store.getState());
    if (dataStatus !== DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return loadAccountLicenses();
    } else {
      const items = selectAccountLicenses(store.getState());
      return Promise.resolve({success: {items: Object.values(items)}});
    }
  },

  reloadAccountLicenses: (): LoadAccountLicensesAction => {
    if (firebaseAuth.getCurrentUser()) {
      return loadAccountLicenses();
    }
  },

}


export default AccountLicensesManager;
