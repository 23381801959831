import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import WithBreakpoints from "../../../../ui/containers/WithBreakpoints";

type Props = {
  renderActions?: () => React.ReactNode;
}
const ScreenTitle = ({children, renderActions}: Props) => {

  return (
    <WithBreakpoints>
      {(bps) =>
        (
          <Box  display={bps.mdUp ? "flex" : "block"}
               mb={2}
               mt={6}
               justifyContent="space-between"
               alignItems="center">
            <Box>
              <Typography color="secondary" variant="h5">
                {children}
              </Typography>
            </Box>
            <Box ml={bps.mdUp ? 1 : 0} mt={bps.mdUp ? 0 : 2}>
              {renderActions && renderActions()}
            </Box>
          </Box>
        )}</WithBreakpoints>
  )
}

export default ScreenTitle;