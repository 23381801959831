import React, {Component} from 'react';
import IfFirebaseAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseAuthed";
import IfFirebaseUnAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseUnAuthed";
import LoginForm from "amn/modules/iam/login/components/LoginForm";
import {Redirect} from "react-router-dom";
import Box from "@material-ui/core/Box";
import {WhiteBox} from "amn/ui/containers/Boxes";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import Hidden from "@material-ui/core/Hidden";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import Button from "@material-ui/core/Button";


type Props = {
  title?: string;
  location?: any;
};

type State = {
  showLogin: boolean;
}

class AmnAuditorSignupScreen extends Component<Props, State> {

  state = {
    showLogin: false
  }

  toggleForm = () => {
    this.setState({showLogin: !this.state.showLogin});
  }

  onLogin = () => {

  }

  render() {
    const {from} = this.props.location?.state || {from: {pathname: "/"}};
    return (
      <div>
        <IfFirebaseAuthed>
          <>
            {/*<Redirect to="/products/amn-auditor/trial"/>*/}
            <Redirect to={from}/>
          </>
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          {this.renderContent()}
        </IfFirebaseUnAuthed>
      </div>
    );
  }

  renderContent = () => {
    const {showLogin} = this.state;
    return (
      <Box minHeight={"100vh"}>
        {/*<Container maxWidth="lg">*/}
        <Box display="flex">
          <Hidden smDown>
            <Box minHeight={"100vh"}
                 flex={3}
                 display="flex"
                 flexDirection="column"
                 justifyContent="center"
                 color="white"
                 textAlign="center"
                 alignItems="center"
                 style={{
                   backgroundImage: `url(${require("assets/images/slides/slide-bg2.jpg")})`
                 }}>
              <Box>
                <img alt="Amn Auditor"
                     style={{height: 122}}
                     src={require("assets/images/logo/amn-auditor-w.png")}/>
              </Box>
              <Box mt={4} fontSize="h5.fontSize" fontWeight="fontWeightMedium">
                Logiciel Desktop <br/>
                de conduite d'Audit Cybersécurité
              </Box>
              <Box mt={4} fontSize="h4.fontSize" fontWeight="fontWeightMedium">
                Assayez le Gratuitement <br/>
                pendant <Box component="span" fontWeight="fontWeightBold">14 jours</Box>
              </Box>
              <Box mt={2} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" mr={1}>
                  {/*<Icon fontSize="small" color="primary"/>*/}
                  <CreditCardOutlinedIcon fontSize="small"/>
                </Box>
                <Box fontSize="caption.fontSize">
                  Sans Carte Bancaire
                </Box>
              </Box>
            </Box>
          </Hidden>
          <WhiteBox flex={2} pl={4} pr={4}>
            <Box pt={4} display="flex" justifyContent="flex-end">
              <Button color="secondary"
                      onClick={this.toggleForm}
                      startIcon={showLogin ? <PersonAddOutlinedIcon/> : <ExitToAppOutlinedIcon/>}
                      size="small">
                {showLogin ? "Créer un compte" : "J'ai déjà un compte"}

              </Button>
            </Box>
            {this.renderForm()}
          </WhiteBox>
        </Box>
        {/*</Container>*/}
      </Box>
    );
  }

  renderForm = () => {
    if (this.state.showLogin) {
      return (
        <LoginForm onLoginSuccess={this.onLogin}
                   key="login"
                   config={{
                     initialScreen: "login",
                     allowLogin: true,
                     allowSignUp: false,
                     theme: {
                       hideMainScreenTitle: true,
                       logo: require("assets/images/logo/amn-auditor.png"),
                     }
                   }}/>
      );
    } else {
      return (
        <LoginForm onLoginSuccess={this.onLogin}
                   key="signup"
                   config={{
                     allowLogin: false,
                     allowSignUp: true,
                     initialScreen: "signUp",
                     theme: {
                       hideMainScreenTitle: true,
                       logo: require("assets/images/logo/amn-auditor.png"),
                     }
                   }}/>
      );
    }
  }
}

export default AmnAuditorSignupScreen;