import type {ListResponse} from "amn/model/common/Responses";
import type {Subscription, SubscriptionPlan} from "../../model/account/Subscription";
import SubscriptionsApi from "../api/portal/SubscriptionsApi";
import {CreateSubscriptionResponse} from "../../model/account/Subscription";


const SubscriptionsBackendAdapter = {

  loadAccountSubscriptions: (): Promise<ListResponse<Subscription>> => {
    return SubscriptionsApi.getAccountSubscriptions();
  },

  loadAccountSubscription: (subscriptionId:string): Promise<Subscription> => {
    return SubscriptionsApi.getAccountSubscription(subscriptionId);
  },


  createAccountSubscription: (plan: SubscriptionPlan): Promise<CreateSubscriptionResponse> => {
    return SubscriptionsApi.createSubscription(plan);
  },

  completeSubscriptionCheckout: (subscriptionId:string): Promise<CreateSubscriptionResponse> => {
    return SubscriptionsApi.completeSubscriptionCheckout(subscriptionId);
  },


}

export default SubscriptionsBackendAdapter;
