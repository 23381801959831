import store from "amn/store/index";
import type {ActionPromise} from "../common";
import CustomerInfoBackendAdapter from "amn/backend/adapters/CustomerInfoBackendAdapter";
import type {CustomerInfo} from "amn/model/account/Subscription";


export const GET_CUSTOMER_INFO_REQUEST = 'app/standards/GET_CUSTOMER_INFO_REQUEST';
export const GET_CUSTOMER_INFO_SUCCESS = 'app/standards/GET_CUSTOMER_INFO_SUCCESS';
export const GET_CUSTOMER_INFO_ERROR = 'app/standards/GET_CUSTOMER_INFO_ERROR';


export type GetAccountCustomerInfoAction = ActionPromise<{}, CustomerInfo>;

export function getAccountCustomerInfo(): GetAccountCustomerInfoAction {
  return store.dispatch({
    types: [GET_CUSTOMER_INFO_REQUEST, GET_CUSTOMER_INFO_SUCCESS, GET_CUSTOMER_INFO_ERROR],
    promise: CustomerInfoBackendAdapter.getCustomerInfo()
  });
}
