import React, {Component} from 'react';
import isEmpty from "lodash/isEmpty";
import type {ErrorItem} from "amn/model/common/Responses";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LaptopChromebookOutlinedIcon from "@material-ui/icons/LaptopChromebookOutlined";
import ContractIcon from "amn/ui/icons/ContractIcon";
import SubscriptionCanceledIcon from "amn/ui/icons/SubscriptionCanceledIcon";
import ProjectsIcon from "amn/ui/icons/ProjectsIcon";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import type {License} from "amn/model/account/Subscription";
import {
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY,
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY
} from "amn/model/account/Subscription";
import SwipeableViews from 'react-swipeable-views';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import {NavLink} from "react-router-dom";
import PortalPaths from "../../../../common/routes/PortalPaths";
import {withSnackbar} from "notistack";
import {withTranslation} from "react-i18next";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {BackBox, WhiteBox} from "amn/ui/containers/Boxes";
import {Backdrop, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {selectUser} from "amn/store/selectors/iam/user.selectors";
import type {User} from "amn/model/account/User";
import Alert from "@material-ui/lab/Alert";
import YouTubeVideo from "amn/ui/diverse/YouTubeVideo";
import {createAccountSubscription} from "amn/store/actions/account/account-subscriptions.actions";
import LicensesBackendAdapter from "amn/backend/adapters/LicensesBackendAdapter";

type Props = {
  user: User;
};
const STEP_PLAN = "PLAN";
const STEP_PROCESSING = "PROCESSING";
const STEP_SUCCESS = "SUCCESS";
const STEP_DOWNLOAD = "DOWNLOAD";
const STEP_EMAIL_SUCCESS = "STEP_EMAIL_SUCCESS";
const STEP_GUIDE = "GUIDE";


const STEPS = {
  [STEP_PLAN]: {name: STEP_PLAN, index: 0, title: "Sélectionner un plan"},
  [STEP_PROCESSING]: {name: STEP_PROCESSING, index: 1, title: "Création de la licence"},
  [STEP_SUCCESS]: {name: STEP_SUCCESS, index: 1, title: "Création de la licence"},
  [STEP_DOWNLOAD]: {name: STEP_DOWNLOAD, index: 2, title: "Télécharger l'application"},
  [STEP_EMAIL_SUCCESS]: {name: STEP_EMAIL_SUCCESS, index: 3, title: "Email d'instruction"},
  [STEP_GUIDE]: {name: STEP_GUIDE, index: 4, title: "Première prise en main"},
}

type State = {
  activeStep: STEP_PLAN | STEP_PROCESSING | STEP_SUCCESS | STEP_DOWNLOAD | STEP_EMAIL_SUCCESS;
  createdLicense?: License;
  email: string;
};

class AmnAuditorTrialWizard extends Component<Props, State> {

  constructor(props) {
    super(props);
    const {plan} = props;
    this.state = {
      email: props.user?.email,
      createdLicense: null,
      activeStep: plan ? STEP_PROCESSING : STEP_PLAN
    }
  }

  componentDidMount() {
    this.start();
  }

  start = async () => {
    const {plan} = this.props;
    if (plan) {
      await this.startTrial(plan);
    }
  }

  startTrial = async (plan) => {
    try {
      this.goToStep(STEP_PROCESSING);
      const result = await createAccountSubscription(plan);
      if (result.success.license) {
        this.setState({activeStep: STEP_SUCCESS, createdLicense: result.success.license});
      }
      // delay(() => {
      //   this.setState({activeStep: STEP_SUCCESS, createdLicense: null});
      // }, 4000)
    } catch (ex) {
      const {enqueueSnackbar} = this.props;
      if (!isEmpty(ex?.error?.errors)) {
        const error: ErrorItem = ex.error.errors[0];
        enqueueSnackbar(error?.title, {variant: 'error'});
      } else {
        enqueueSnackbar("Une erreur est survenue de la création de votre license d'essai", {variant: 'error'});
      }
      this.setState({activeStep: STEP_PLAN});
    }
  }

  goToStep = (step) => {
    window.scrollTo(0, 0);
    this.setState({activeStep: step});
  }

  getActiveStepConfig = () => {
    const {activeStep} = this.state;
    return STEPS[activeStep];
  }

  onEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  onSubmitEmail = async (event) => {
    debugger;
    event.preventDefault();
    try {
      const {createdLicense, email} = this.state;
      await LicensesBackendAdapter.sendLicenseInstructions(createdLicense?.id || "a6c9a8b8-c22e-452c-aa4d-f95986e7cd60", email);
      this.goToStep(STEP_EMAIL_SUCCESS);
    } catch (ex) {
      const {enqueueSnackbar} = this.props;
      if (!isEmpty(ex?.error?.errors)) {
        const error: ErrorItem = ex.error.errors[0];
        enqueueSnackbar(error?.title, {variant: 'error'});
      } else {
        enqueueSnackbar("Une erreur est survenue de la création de votre license d'essai", {variant: 'error'});
      }
    }
  }

  render() {
    const {activeStep} = this.state;
    const activeStepConfig = this.getActiveStepConfig();
    return (
      <>
        <SwipeableViews disabled index={activeStepConfig.index}>
          <Box>
            {activeStep === STEP_PLAN && this.renderPlanSelector()}
          </Box>
          <Box>
            {activeStep === STEP_SUCCESS && this.renderSuccess()}
          </Box>
          <Box>
            <WhiteBox p={3} pt={4}>
              {activeStep === STEP_DOWNLOAD && this.renderDownload()}
            </WhiteBox>
          </Box>
          <Box>
            <WhiteBox p={3} pt={4}>
              {activeStep === STEP_EMAIL_SUCCESS && this.renderEmailSuccess()}
            </WhiteBox>
          </Box>
          <Box>
            <WhiteBox p={3} pt={4}>
              {activeStep === STEP_GUIDE && this.renderGuide()}
            </WhiteBox>
          </Box>
        </SwipeableViews>
        {activeStep === STEP_PROCESSING && this.renderProcessing()}
      </>);
  }


  renderPlanSelector = () => {
    return (

      <WhiteBox pt={4}>
        <Box pb={4} mt={2} maxWidth="100%">
          <Box textAlign="center">
            <Box mb={3} color="primary.main" fontWeight={600} fontSize="h6.fontSize">Sélectionner un Plan</Box>
            <div>
              <img src={require("assets/images/icons/certificate.svg")}
                   style={{maxHeight: 72}}
                   alt="Amn Brains"/>
            </div>
            <div>
              <Box mt={1.5} fontSize="body1.fontSize" color="secondary.main" fontWeight={600}>
                1 Licence Amn Auditor
              </Box>

              <Box mt={3} display="flex" justifyContent="center">
                <Box color="secondary.main" fontWeight="fontWeightMedium">

                  {this.renderAdvantage("1 Application Desktop", LaptopChromebookOutlinedIcon)}

                  {this.renderAdvantage("Activable sur 1 machine", ContractIcon)}

                  {this.renderAdvantage("Résiliable à tout moment", SubscriptionCanceledIcon)}

                  {this.renderAdvantage("Projets d'Audit illimités", ProjectsIcon)}

                </Box>
              </Box>

              <Box mt={4} mb={4}>
                <Box color="secondary.main">
                  <Box>
                    Essayez Amn Auditor <b>GRATUITEMENT</b>
                  </Box>
                  <Box mt={0.5}>
                    pendant
                    &nbsp;<Box display="inline" color="primary.main" fontSize="h6.fontSize"><b>14 jours</b></Box>
                  </Box>
                </Box>
                <Box mt={1} display="flex" justifyContent="center">
                  <Box display="flex" color="text.secondary" alignItems="center">
                    <Box display="flex" alignItems="center" mr={1}>
                      {/*<Icon fontSize="small" color="primary"/>*/}
                      <CreditCardOutlinedIcon fontSize="small"/>
                    </Box>
                    <Box fontSize="caption.fontSize">
                      Sans Carte Bancaire
                    </Box>
                  </Box>
                </Box>
              </Box>
              <SwipeableViews enableMouseEvents style={{padding: '0 56px',}} slideStyle={{padding: '0 4px',}}>
                <WhiteBox color="secondary.main"
                          borderColor="primary.main"
                          border={2}
                          display="flex"
                          flexDirection="column"
                          alignItems="center" p={2} pt={4} pb={4}>
                  <Box flex={1}
                       fontSize="h6.fontSize"
                       position="relative"
                       textAlign="center">
                    <b>
                      Plan Annuel
                    </b>
                    <Box position="absolute"
                         fontSize="body2.fontSize"
                         fontWeight="fontWeightBold"
                         color="primary.main"
                         p={0.5}
                         bgcolor="success.bg"
                         display="flex"
                         alignItems="center"
                         justifyContent="center"
                      // maxWidth={68}
                         right={"calc(50% - 50px)"}
                      //    right={-6}
                         bottom={-28}>
                      <Box component="span"
                           fontSize={9}
                           fontWeight="fontWeightMedium"
                           color="primary.main">
                        économisez
                      </Box>&nbsp;<span>20%</span>
                    </Box>
                  </Box>
                  <Box mt={3}>

                    <Box flex={1}
                         display="flex"
                         alignItems="center"
                         justifyContent="center">
                      <Box fontSize="h4.fontSize">
                        <b>499</b>
                      </Box>
                      <Box fontSize="subtitle1.fontSize">


                        <b>,99&nbsp;€ HT</b>
                      </Box>
                    </Box>
                  </Box>

                  <Box mt={3} display="flex">
                    <Box flex={1}
                         textAlign="center">
                      <Button color="primary"
                              onClick={() => this.startTrial(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY)}
                              variant="contained">
                        Démarrer l'essai
                      </Button>
                    </Box>
                  </Box>
                </WhiteBox>
                <WhiteBox color="secondary.main"
                          borderColor="secondary.main"
                          border={2}
                          display="flex"
                          flexDirection="column"
                          alignItems="center" p={2} pt={4} pb={4}>
                  <Box flex={1}
                       fontSize="h6.fontSize"
                       textAlign="center">
                    <b>
                      Plan Mensuel
                    </b>
                  </Box>
                  <Box mt={3} display="flex">

                    <Box flex={1}
                         display="flex"
                         alignItems="center"
                         justifyContent="center">
                      <Box fontSize="h4.fontSize">
                        <b>59</b>
                      </Box>
                      <Box fontSize="subtitle1.fontSize">
                        <b>,99&nbsp;€ HT</b>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={3} display="flex">
                    <Box flex={1}
                         fontSize="h6.fontSize"
                         textAlign="center">
                      <Button color="secondary"
                              onClick={() => this.startTrial(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY)}
                              variant="outlined">
                        Démarrer l'essai
                      </Button>
                    </Box>
                  </Box>
                </WhiteBox>
              </SwipeableViews>
            </div>
          </Box>
        </Box>
      </WhiteBox>
    )
  }

  renderAdvantage(title, Icon) {
    return (
      <Box mt={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={2}>
          <Icon fontSize="small" color="primary"/>
        </Box>
        <Box fontSize="body2.fontSize" display="flex" alignItems="center">
          {title}
        </Box>
      </Box>
    );
  }

  renderProcessing = () => {
    return (
      <Backdrop open={true} style={{zIndex: 99}}>
        <Box color={"white"} mt={4} minHeight={160} textAlign="center">
          <Box>
            Nous préparons votre licence d'essai
          </Box>
          <Box mt={6} pb={4}>
            <CircularProgress color="inherit"/>
          </Box>
        </Box>
      </Backdrop>

    )
  }

  renderSuccess = () => {
    return (
      <WhiteBox p={3} pt={4}>
        <Box mt={4} textAlign="center">
          <Box mb={3} display="flex" justifyContent="center">
            <dotlottie-player
              src={require("assets/images/icons/success.lottie")}
              autoplay
              style={{height: 80, width: 80}}
            />
          </Box>
          <div>
            <Typography variant="subtitle2" color="secondary">
              Votre license a été créée avec succès
            </Typography>
          </div>
          <Box mt={3} pl={4} pr={4}>
            <Typography variant="subtitle1" color="secondary">
              Pour commencer à utiliser Amn Auditor, suivez les étapes suivantes !
            </Typography>
          </Box>
          <Box mt={6} mb={4} display="flex" justifyContent="center">
            <Button color="primary"
                    endIcon={<ChevronRightOutlinedIcon/>}
                    onClick={() => this.goToStep(STEP_DOWNLOAD)}
                    variant="contained">
              Insctructions d'installation
            </Button>
          </Box>
        </Box>
      </WhiteBox>
    )
  }


  renderDownload = () => {
    return (
      <Box>

        <Box mt={2} mb={2}>

          <Box mt={3} textAlign="center" alignItems="flex-end" flexDirection="column">
            <img src={require("assets/images/logo/amn-auditor.svg")}
                 style={{maxHeight: 60, minWidth: 120}}
                 alt="Amn Brains"/>

          </Box>
          <Box mt={3} color="secondary.main">
            <Box textAlign="center" fontWeight="fontWeightMedium">
              <Box color="primary.main" fontSize="h6.fontSize">Instructions d'installation</Box>

              <Box mt={2}>
                Pour commencer l'utilisation du logiciel, il faut le Télécharger, l'Installer sur une machine Desktop et
                ensuite, activer la licence d'essai.
              </Box>
              <Box>
                Nous vous enverrons les instructions d'installation et d'activation par email.
              </Box>
            </Box>

          </Box>

          <Box mt={6}>

            <Box mb={2} textAlign="center">
              Envoyez-moi les instructions d'installation et d'activation par email sur :
            </Box>
            <form onSubmit={this.onSubmitEmail}>
              <TextField variant="outlined"
                         label="Mon adresse email"
                         fullWidth
                         type="email"
                         onChange={this.onEmailChange}
                         value={this.state.email}
                         required/>


              <Box mt={2} mb={4} display="flex" justifyContent="center">
                <Button color="primary" type="submit"
                        endIcon={<ChevronRightOutlinedIcon/>}
                        variant="contained">
                  Envoyer
                </Button>
              </Box>
            </form>
          </Box>

        </Box>

      </Box>
    )
  }

  renderEmailSuccess = () => {
    return (
      <Box mt={4}>
        <Box mb={3} display="flex" justifyContent="center">
          <dotlottie-player
            src={require("assets/images/icons/success.lottie")}
            autoplay
            style={{height: 80, width: 80}}
          />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle2" color="secondary">
            Les instructions d'installation ont bien été envoyées.
          </Typography>
        </Box>
        <Box mt={2}>
          <Alert severity="error">
            Si vous n'avez pas reçu l'email, merci de vérifier dans vos <b>SPAM</b>.
          </Alert>
        </Box>
        <Box mt={2} textAlign="center">
          <Box display="inline-block" bgcolor="info.bg">
            Les instructions d'installation ainsi que le code d'activation restent, tout de même, accessibles à tout
            moment depuis votre espace en ligne.
          </Box>
        </Box>
        <Box mt={6} mb={4} display="flex" justifyContent="center">
          <Button color="primary"
                  endIcon={<ChevronRightOutlinedIcon/>}
                  onClick={() => this.goToStep(STEP_GUIDE)}
                  variant="contained">
            Suivant
          </Button>
        </Box>
      </Box>
    )
  }


  renderGuide = () => {

    return (
      <Box textAlign="center" mt={2} p={2}>

        <Box mt={3} color="secondary.main">
          <Box color="primary.main" fontWeight="fontWeightMedium" fontSize="h6.fontSize">Première prise en main</Box>

          <Box mt={2}>
            La vidéo ci-dessous vous aidera à bien débuter avec Amn Auditor.
          </Box>
          <Box mt={4} alignItems="flex-end" flexDirection="column">

            <YouTubeVideo videoId="AZ9qYi9M5Z0" className="ytv-rspsv" opts={{
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                color: "#109c1",
              },
            }}/>
          </Box>

          <Box mt={2} textAlign="center">
            <a href="https://amnbrains.com/amn-auditor-early-adopters-download#videos" target="_blank">
              Cliquer ici pour accéder à l'ensemble des guides et tutoriels vidéos.
            </a>
          </Box>
        </Box>

        <Box mt={5}>
          Si vous avez besoin d'aide, ou vous avez des questions ou des demandes particulières, n'hésitez pas à nous
          contacter sur
        </Box>
        <BackBox p={2} mt={2}>
          <a href="mailto:support@amnbrains.com">
            <Box component="span" color="primary.main">
              support@amnbrains.com
            </Box>
          </a>
        </BackBox>


        <Box mt={4} mb={4}>
          <Button component={NavLink}
                  to={PortalPaths.accountLicensesUrl()}
                  color="primary"
                  variant="contained">Aller à mon compte</Button>
        </Box>

      </Box>
    )
  }

  static mapStateToProps = (state) => {
    return {
      user: selectUser(state),
    }
  };
}


export default connect(AmnAuditorTrialWizard.mapStateToProps)(withSnackbar(
  withTranslation(I18N_NS_STANDARDS)(
    AmnAuditorTrialWizard
  )
));
