import store from "amn/store/index";
import type {ActionPromise} from "../common";
import type {CreationResponse} from "amn/model/common/Responses";
import UserBackendAdapter from "amn/backend/adapters/UserBackendAdapter";
import type {User} from "amn/model/account/User";


export const SET_USER_REQUEST = 'app/standards/SET_USER_REQUEST';
export const SET_USER_SUCCESS = 'app/standards/SET_USER_SUCCESS';
export const SET_USER_ERROR = 'app/standards/SET_USER_ERROR';


export type CreateOrUpdateUserAction = ActionPromise<{ user: User }, CreationResponse>;

export function createOrUpdateUser(user: User): CreateOrUpdateUserAction {
  return store.dispatch({
    types: [SET_USER_REQUEST, SET_USER_SUCCESS, SET_USER_ERROR],
    user,
    promise: UserBackendAdapter.createOrUpdateUser(user)
  });
}


export const GET_ME_REQUEST = 'app/standards/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'app/standards/GET_ME_SUCCESS';
export const GET_ME_ERROR = 'app/standards/GET_ME_ERROR';


export type GetMeAction = ActionPromise<{}, User>;

export function getMe(user: User): GetMeAction {
  return store.dispatch({
    types: [GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_ERROR],
    user,
    promise: UserBackendAdapter.getMe(user)
  });
}
