import React from 'react';
import type {SubscriptionStatus} from "amn/model/account/Subscription";
import {getSubscriptionStatusOption} from "amn/model/account/Subscription";
import GenericOptionIcon from "amn/ui/forms/GenericOptionIcon";
import {SvgIconProps} from "@material-ui/core/SvgIcon";

type Props = {
  status: SubscriptionStatus;
} & SvgIconProps;

const AccountSubscriptionStatusIcon = ({status, ...props}: Props) => {
  const option = getSubscriptionStatusOption(status);
  return (
    <>
      <GenericOptionIcon option={option} fontSize="small" {...props}/>
    </>
  );
};

export default AccountSubscriptionStatusIcon;