import client from "../common/RestAuthClient";


const CustomerApi = {

  getAccountCustomerInfo: async () => {
    return client.get("/api/customer-info");
  },

  createUpdatePaymentSourcesPage: async () => {
    return client.get("/api/customer-info/actions/create-payment-sources-page");
  },

  createPortalSession: async () => {
    return client.get("/api/customer-info/actions/create-portal-session");
  },
}


export default CustomerApi;