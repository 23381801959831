import CustomerApi from "../../backend/api/portal/CustomerApi";
import Config from "../../Config";

window.Chargebee.init({
  site: Config.CHARGEBEE_SITE,
  publishableKey: Config.CHARGEBEE_PUBLISHABLE_KEY
});


export const PORTAL_SECTION_PAYMENT_SOURCES = "PAYMENT_SOURCES";
export const PORTAL_SECTION_ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const PORTAL_SECTION_BILLING_HISTORY = "BILLING_HISTORY";
export const PORTAL_SECTION_SUBSCRIPTION_DETAILS = "SUBSCRIPTION_DETAILS";
export const PORTAL_SECTION_ADDRESS = "ADDRESS";

export type ChargebeePortalSection =
  PORTAL_SECTION_PAYMENT_SOURCES |
  PORTAL_SECTION_ACCOUNT_DETAILS |
  PORTAL_SECTION_BILLING_HISTORY |
  PORTAL_SECTION_SUBSCRIPTION_DETAILS |
  PORTAL_SECTION_ADDRESS;


export const getChargebeeInstance = () => {
  return window.Chargebee.getInstance();
}


export const openPortalSection = async (section: ChargebeePortalSection, params: Object, handlers = {}) => {
  const data = await CustomerApi.createPortalSession();
  getChargebeeInstance().setPortalSession(() => Promise.resolve(data));
  let cbPortal = getChargebeeInstance().createChargebeePortal();
  cbPortal.openSection(
    {
      sectionType: window.Chargebee.getPortalSections()[section],
      params,
    },
    {
      ...handlers,
      close: () => {
        handlers?.close && handlers.close()
        getChargebeeInstance().logout()
      }
    });
}
