import {DATA_LOADED} from "../../reducers/common";
import store from "amn/store";
import firebaseAuth from "amn/common/firebase/auth/FirebaseAuth";
import {
  getAccountCustomerInfo,
  GetAccountCustomerInfoAction
} from "../../actions/account/account-customer-info.actions";
import {
  selectAccountCustomerInfo,
  selectAccountCustomerInfoDataStatus
} from "../../selectors/account/account-customer-info.selectors";


const AccountCustomerInfoManager = {

  init: () => {
    firebaseAuth.addAuthStateListener((user) => {
      if (user) {
        AccountCustomerInfoManager.loadAccountCustomerInfo();
      }
    });
  },

  loadAccountCustomerInfo: (): GetAccountCustomerInfoAction => {
    const dataStatus = selectAccountCustomerInfoDataStatus(store.getState());
    if (dataStatus !== DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return getAccountCustomerInfo();
    } else {
      const info = selectAccountCustomerInfo(store.getState());
      return Promise.resolve(info);
    }
  },

  reloadAccountCustomerInfo: (): GetAccountCustomerInfoAction => {
    return getAccountCustomerInfo();
  },

}


export default AccountCustomerInfoManager;
