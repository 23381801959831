import React from 'react';

const SuccessIcon = (props) => (
  <svg {...props} viewBox="0 0 226 226">
    <g>
      <path d="M0,226v-226h226v226z" fill="none" stroke="none" strokeWidth="1" strokeLinecap="butt"/>
      <g>
        <path
          d="M113,28.25c-46.80613,0 -84.75,37.94387 -84.75,84.75c0,46.80613 37.94387,84.75 84.75,84.75c46.80613,0 84.75,-37.94387 84.75,-84.75c0,-46.80613 -37.94387,-84.75 -84.75,-84.75z"
          fill="#ffffff" stroke="none" strokeWidth="1" strokeLinecap="butt"/>
        <path
          d="M113,44.14063c-38.02998,0 -68.85937,30.82939 -68.85937,68.85938c0,38.02998 30.82939,68.85938 68.85938,68.85938c38.02998,0 68.85938,-30.82939 68.85938,-68.85937c0,-38.02998 -30.82939,-68.85937 -68.85937,-68.85937z"
          fill="#1abc9c" stroke="none" strokeWidth="1" strokeLinecap="butt"/>
        <path
          d="M113,28.25c-46.80613,0 -84.75,37.94387 -84.75,84.75c0,46.80613 37.94387,84.75 84.75,84.75c46.80613,0 84.75,-37.94387 84.75,-84.75c0,-46.80613 -37.94387,-84.75 -84.75,-84.75z"
          fill="none" stroke="#1abc9c" strokeWidth="10.59375" strokeLinecap="butt"/>
        <path d="M74.15625,121.82813l23.92422,21.1875l53.76328,-61.79687" fill="none" stroke="#ffffff"
              strokeWidth="10.59375" strokeLinecap="round"/>
      </g>
    </g>
  </svg>
);

export default SuccessIcon;