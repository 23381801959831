import CustomerApi from "../api/portal/CustomerApi";
import type {CustomerInfo} from "amn/model/account/Subscription";


const CustomerInfoBackendAdapter = {

  getCustomerInfo: (): Promise<CustomerInfo> => {
    return CustomerApi.getAccountCustomerInfo();
  },

}

export default CustomerInfoBackendAdapter;
