import produce from "immer";

import type {Reducer} from "../common";
import {DATA_INITIAL, DATA_LOADED, SingleDataState} from "../common";
import type {User} from "amn/model/account/User";
import {GET_ME_SUCCESS, SET_USER_SUCCESS} from "../../actions/iam/user.actions";

export type UserState = SingleDataState<User>

const INITIAL_STATE: UserState = {
  entity: {},
  status: DATA_INITIAL,
}


const user: Reducer = produce((draft: UserState, action) => {
  switch (action.type) {

    case SET_USER_SUCCESS: {
      if (action.success) {
        draft.entity = action.request.user;
        draft.status = DATA_LOADED;
      }
      break;
    }

    case GET_ME_SUCCESS: {
      if (action.success) {
        draft.entity = action.success;
        draft.status = DATA_LOADED;
      }
      break;
    }


    default:
      break;
  }
}, INITIAL_STATE);


export default user;
