import React, {Component} from 'react';
import PortalLayout from "../common/layout/PortalLayout";
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "../../common/routes/PrivateRoute";
import AccountLicensesScreen from "../account/screens/AccountLicensesScreen";
import WelcomeScreen from "../../welcome/screens/HomeScreen";


function SubscriptionsList() {
  return null;
}

class PortalScreen extends Component<Props> {


  render() {
    const {match, projectInfo} = this.props;
    const {path} = match;
    return (
      <PortalLayout>
        <Switch>
          <Route exact path={`/`} component={WelcomeScreen}/>
          <Route exact path={`/account/licenses`} component={AccountLicensesScreen}/>
        </Switch>
      </PortalLayout>
    );
  }


}

export default PortalScreen;