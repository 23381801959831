function handlePromise(action, next) {
  // TODO validate arguments
  const {promise, types, ...request} = action;
  const [REQUEST, SUCCESS, ERROR] = types;

  // dispatch request
  next({...request, type: REQUEST});

  return promise
  // dispatch success
    .then((success) => {
      const payload = {request: {...request}, success};
      next({...payload, type: SUCCESS});
      return payload;
    })
    // dispatch error
    .catch((error) => {
      console.error(error);
      const payload = {request: {...request}, error};
      next({...payload, type: ERROR});
      throw payload;
    });
}

export default ({dispatch}) =>
  ((next) =>
      ((action) => {

        if (typeof action === 'function') {
          // thunk mode
          return action(dispatch);

        } else if (action.promise) {
          // promise mode
          return handlePromise(action, next);
        }
        // else redux basic mode
        return next(action);
      })
  );


export type Payload<T> = {
  success: T;
  request: any;
}

