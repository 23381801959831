import React from "react";
import FirebaseAuthContext , {AuthState}  from "../FirebaseAuthContext";
import {renderAndAddProps} from "render-and-add-props";


const IfFirebaseUnAuthed = ({children}) => {
  return (
    <FirebaseAuthContext.Consumer>
      {(authState: AuthState) =>
        authState.isSignedIn === false
          ? renderAndAddProps(children)
          : null
      }
    </FirebaseAuthContext.Consumer>
  );
};

export default IfFirebaseUnAuthed;