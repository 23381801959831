import React, {Component} from 'react';
import {Auth0Lock} from "auth0-lock";
import Config from "amn/Config";
import {signInWithCustomToken, updateFirebaseCurrentUser} from "amn/common/firebase/auth/auth.actions";
import {getAuth0LockConfig} from "./LoginFormAuth0LockConfig";
import {Auth0LockConfiguration} from "amn/common/auth0/Auth0Lock.types";
import {auth0ProfileToUser} from "amn/model/account/User";
import "./LoginForm.css";

type Props = {
  onLoginSuccess: ()=> void;
  config?: Partial<Auth0LockConfiguration>;
};

type State = {};


class LoginDialog extends Component<Props, State> {


  constructor(props) {
    super(props);
    const config = this.initConfig();
    this.auth0Lock = new Auth0Lock(
      Config.AUTH0_CLIENT_ID,
      Config.AUTH0_DOMAIN,
      config,
    );
  }

  initConfig = () => {
    // TODO i18n
    const {config} = this.props;
    return getAuth0LockConfig({...(config || {}), container: "iam-login-form"})
  }

  componentDidMount() {

    this.auth0Lock.on("authenticated", async (authResult) => {
      await signInWithCustomToken(authResult.idToken);
      this.auth0Lock.getUserInfo(authResult.accessToken, async (error, profile) => {
        if (error) {
          // todo Handle error
          console.log(error)
          return;
        }
        const user = auth0ProfileToUser(profile);
        await updateFirebaseCurrentUser(user);
        // await AuthManager.initUser();
      });
      setTimeout(() => {
        this.props.onLoginSuccess && this.props.onLoginSuccess();

      }, 2000)
    })
    this.auth0Lock.show();
  }

  render() {
    return (
      <div id="iam-login-form"/>
    );
  }
}


export default LoginDialog;



