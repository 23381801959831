import React from 'react';
import {NavLink} from "react-router-dom";
import type {SidebarItemProps} from "./SidebarItem";
import SidebarItem from "./SidebarItem";


const SidebarNavItem = (props: SidebarItemProps) => {

  return (
    <SidebarItem {...props} component={NavLink}/>
  );
};

export default SidebarNavItem;
