import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from "./Routes";
import LoginDialog from "./modules/iam/login/components/LoginDialog";


export default function Root() {

  return (
    <div>
      <CssBaseline/>
      <Routes/>
      <LoginDialog/>
    </div>
  );
}



