import RestClient from "./RestClient";
import FirebaseAuth from "amn/common/firebase/auth/FirebaseAuth";


const client = new RestClient();
client.axios.interceptors.request.use(async (config) => {
    const token = await FirebaseAuth.getCurrentUserIdToken();
    token && (config.headers.Authorization = `Bearer ${token}`);
    return config;
  },
  (error) => Promise.reject(error)
);

export default client;