import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import I18n from "../i18n/I18n";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

type Props = {
  titleIcon?: React.ReactNode;
  title: React.ReactNode;
  onClose?: ()=> void;
  hideCloseButton? : boolean;
};

type State = {};

class DialogHeader extends Component<Props, State> {

  static defaultProps = {
    hideCloseButton : false
  }

  render() {
    const {title, titleIcon, onClose, hideCloseButton} = this.props;
    return (
      <Box pt={1} pb={1}
           display="flex"
           alignItems="center">
        {titleIcon &&
        <Box mr={1}
             display="flex"
             alignItems="center"
             color="secondary.main"
             fontSize="h2.fontSize">
          {titleIcon}
        </Box>}
        <Box flex={1}
             display="flex"
             alignItems="center">
          <Typography variant="h5" color="secondary">
            {title}
          </Typography>
        </Box>
        {!hideCloseButton &&
        <Box display="flex"
             alignItems="center">
          <Tooltip title={<I18n>Close (Esc)</I18n>}>
            <IconButton onClick={onClose}>
              <ClearOutlinedIcon/>
            </IconButton>
          </Tooltip>
        </Box>
        }
      </Box>

    );
  }
}


export default DialogHeader;



