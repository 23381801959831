import type {CreationResponse} from "amn/model/common/Responses";
import firebase from "amn/common/firebase";
import type {User} from "amn/model/account/User";
import FirebaseAuth from "../../../common/firebase/auth/FirebaseAuth";

const COLLECTION_NAME = "users";

export default {

  createOrUpdateUser: async (user: User = {}): Promise<CreationResponse> => {
    await firebase.firestore().collection(COLLECTION_NAME)
      .doc(user.id)
      .set({...user});
    return {
      id: user.id,
    };
  },

  getMe: async (): Promise<User> => {
    const id = FirebaseAuth.getCurrentUserId();
    const doc = await firebase.firestore().collection(COLLECTION_NAME)
      .doc(id)
      .get();
    if (doc.exists) {
      return {id: doc.id, ...doc.data()};
    }
  }

}
