import type {PersistentEntity} from "../common/Entity";
import {Auth0UserProfile} from "auth0-js";


export type User = {
  name: string;
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  nickname: string;
  picture: string;
  sub: string;
  username?: string;
  clientID: string;
  gender?: string;
  locale?: string;

  // updated_at: string;
  // created_at: string;
  // user_metadata?: any;
  // app_metadata?: any;
} & PersistentEntity;


export const auth0ProfileToUser = (profile: Auth0UserProfile = {}) => {
  return {
    name: profile.name,
    email: profile.email,
    nickname: profile.nickname,
    picture: profile.picture,
    sub: profile.sub,
    username: profile.username,
    clientID: profile.clientID,
    gender: profile.gender,
    locale: profile.locale,
    familyName: profile.family_name,
    givenName: profile.given_name,
    id: profile.user_id || profile.sub,
    emailVerified: profile.email_verified,
    creationDate: profile.created_at,
    lastModificationDate: profile.updated_at,
    // user_metadata?: any;
    // app_metadata?: any;
  }
}