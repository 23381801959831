import React, {Component} from 'react';
import '@dotlottie/player-component';
import firebase, {configureFirebase} from "amn/common/firebase";
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import Config from "amn/Config";
import {BrowserRouter as Router} from 'react-router-dom';

import store from 'amn/store';
import ThemeProvider from 'amn/modules/theme/ThemeProvider';
import FirebaseAuthProvider from 'amn/common/firebase/auth/react/FirebaseAuthProvider';
import AccountLicensesManager from "./store/actors/account/AccountLicensesManager";
import AccountSubscriptionsManager from "./store/actors/account/AccountSubscriptionsManager";
import IAMManager from "./store/actors/iam/IAMManager";
import AccountCustomerInfoManager from "./store/actors/account/AccountCustomerInfoManager";
import Root from "./Root";
import {Helmet} from "react-helmet";


type Props = {
  i18n?: Object;
}


export default class App extends Component<Props> {

  componentDidMount() {
    configureFirebase()
    if (window.location.hostname === "localhost" || window.location.hostname.startsWith("m.local")) {
      firebase.auth().useEmulator('http://localhost:9099/');
      firebase.firestore().useEmulator("localhost", 8080);
    }

    IAMManager.init();
    AccountSubscriptionsManager.init();
    AccountLicensesManager.init();
    AccountCustomerInfoManager.init();

  }


  render() {
    return (
      <Provider store={store}>
        <ThemeProvider>
          <Router>
            <div>
              <Helmet titleTemplate="Amn Brains | %s">
                <title lang="fr">Portail Clients</title>
                <meta name="description"
                      content="Portail Client Amn Brains"/>
              </Helmet>
              <FirebaseAuthProvider firebase={firebase} {...Config.FIREBASE_CONFIG}>
                <SnackbarProvider>
                  <Root/>
                </SnackbarProvider>
              </FirebaseAuthProvider>
            </div>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}
