import React, {Component} from 'react';
import {connect} from "react-redux";
import type {DataStatus} from "amn/store/reducers/common";
import {DATA_LOADING} from "amn/store/reducers/common";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import type {LookupTable} from "amn/model/common/Divers";
import type {License, SubscriptionPlan} from "amn/model/account/Subscription";
import {
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY,
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY
} from "amn/model/account/Subscription";
import LaptopChromebookOutlinedIcon from '@material-ui/icons/LaptopChromebookOutlined';
import {
  selectAccountLicenses,
  selectAccountLicensesDataStatus
} from "amn/store/selectors/account/account-licenses.selectors";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import {withSnackbar} from "notistack";
import {withTranslation} from "react-i18next";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import SubscriptionsApi from "amn/backend/api/portal/SubscriptionsApi";
import {getChargebeeInstance} from "amn/common/chargebee";
import AccountSubscriptionsManager from "amn/store/actors/account/AccountSubscriptionsManager";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogHeader from "amn/ui/containers/DialogHeader";
import delay from "lodash/delay";
import Box from "@material-ui/core/Box";
import AccountLicenseActivationToken from "./AccountLicenseActivationToken";
import ContractIcon from "amn/ui/icons/ContractIcon";
import SubscriptionCanceledIcon from "amn/ui/icons/SubscriptionCanceledIcon";
import ProjectsIcon from "amn/ui/icons/ProjectsIcon";

type Props = {
  dataStatus: DataStatus;
  licenses: LookupTable<License>;
};

type State = {
  createdLicense?: License;
  showDialog: boolean;
  step: "PLAN" | "SUCCESS"
};


class AccountLicensesAddNewButton extends Component<Props, State> {

  state = {
    createdLicense: null,
    showDialog: false,
    step: "PLAN"
  }

  checkout = async (plan: SubscriptionPlan) => {

    const data = await SubscriptionsApi.createAddSubscriptionCheckout(plan);
    this.setState({showDialog: false, step: "CHECKOUT"});
    getChargebeeInstance().openCheckout({
      hostedPage: () => Promise.resolve(data?.hostedPage),
      success: async (hostedPageId) => {
        const result = await AccountSubscriptionsManager.completeSubscriptionCheckout(data.subscriptionId);
        const createdLicense = result?.success?.license;
        createdLicense && this.setState({createdLicense});
      },
      close: () => {
        delay(() => {
          if (this.state.createdLicense) {
            this.setState({showDialog: true, step: "SUCCESS"});
          }
        }, 500)

      }
    });
  }

  openDialog = () => {
    this.setState({showDialog: true, step: "PLAN", createdLicense: null});
  }

  closeDialog = () => {
    this.setState({showDialog: false, step: "PLAN", createdLicense: null});
  }

  render() {

    const {dataStatus, licenses} = this.props;
    const {step} = this.state;
    return (
      <LoadingContainer loading={dataStatus === DATA_LOADING}>
        {!isEmpty(licenses) &&
          <Button variant="contained"
                  color="primary"
                  onClick={this.openDialog}>
            Acheter une nouvelle Licence
          </Button>
        }
        <Dialog open={this.state.showDialog}
                onClose={this.closeDialog}
                fullWidth
                maxWidth={"sm"}>
          <DialogTitle>
            <DialogHeader title={""}
                          onClose={this.closeDialog}/>
          </DialogTitle>
          <DialogContent>
            <Box>
              {step === "PLAN" && this.renderPlanSelector()}
              {step === "SUCCESS" && this.renderSuccess()}
            </Box>
          </DialogContent>
        </Dialog>
      </LoadingContainer>);
  }

  renderPlanSelector = () => {
    return (
      <Box pb={4}>
        <Box textAlign="center">
          <div>
            <img src={require("assets/images/icons/certificate.svg")}
                 style={{maxHeight: 88}}
                 alt="Amn Brains"/>
          </div>
          <div>
            <Box mt={1.5} fontSize="h6.fontSize" color="primary.main" fontWeight={600}>
              1 Licence Amn Auditor
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
              <Box color="secondary.main" fontWeight="fontWeightMedium">

                {this.renderAdvantage("1 Application Desktop", LaptopChromebookOutlinedIcon)}

                {this.renderAdvantage("Activable sur 1 machine", ContractIcon)}

                {this.renderAdvantage("Résiliable à tout moment", SubscriptionCanceledIcon)}

                {this.renderAdvantage("Projets d'Audit illimités", ProjectsIcon)}

              </Box>
            </Box>

            <Box width="90%"
                 ml="auto"
                 mr="auto"
                 pb={3}
                 mt={6}
                 color="secondary.main">
              <Box display="flex">
                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <b>
                    Plan Annuel
                  </b>
                </Box>

                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <b>
                    Plan Mensuel
                  </b>
                </Box>
              </Box>


              <Box mt={3} display="flex">
                <Box flex={1}
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                  <Box fontSize="h4.fontSize">
                    <b>499</b>
                  </Box>
                  <Box fontSize="subtitle1.fontSize" position="relative">

                    <Box position="absolute"
                         fontSize="body2.fontSize"
                         fontWeight="fontWeightBold"
                         color="primary.main"
                         p={0.5}
                         bgcolor="success.bg"
                         display="flex"
                         alignItems="center"
                         justifyContent="center"
                         // maxWidth={68}
                         right={-8}
                         top={-34}>
                      <Box component="span"
                           fontSize={9}
                           fontWeight="fontWeightMedium"
                           color="primary.main">
                        économisez
                      </Box>&nbsp;<span>20%</span>
                    </Box>
                    <b>&nbsp;€ HT</b>
                  </Box>
                </Box>

                <Box flex={1}
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                  <Box fontSize="h4.fontSize">
                    <b>59</b>
                  </Box>
                  <Box fontSize="subtitle1.fontSize">
                    <b>&nbsp;€ HT</b>
                  </Box>
                </Box>
              </Box>

              <Box mt={3} display="flex">
                <Box flex={1}
                     textAlign="center">
                  <Button color="primary"
                          onClick={() => this.checkout(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY)}
                          variant="contained">
                    Choisir ce plan
                  </Button>
                </Box>

                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <Button color="secondary"
                          onClick={() => this.checkout(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY)}
                          variant="outlined">
                    Choisir ce plan
                  </Button>
                </Box>
              </Box>

            </Box>
          </div>
        </Box>
      </Box>
    )
  }

  renderAdvantage(title, Icon) {
    return (
      <Box mt={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={2}>
          <Icon fontSize="small" color="primary"/>
        </Box>
        <Box fontSize="body1.fontSize">
          {title}
        </Box>
      </Box>
    );
  }

  renderSuccess = () => {
    const {createdLicense} = this.state;
    return (
      <Box textAlign="center">
        <Box display="flex" justifyContent="center">
          <dotlottie-player
            src={require("assets/images/icons/success.lottie")}
            autoplay
            style={{height: 80, width: 80}}
          />

        </Box>
        <Box mt={2} fontSize="subtitle1.fontSize" color="secondary.main">
          Votre licence a été créée avec succès. <br/>
          Copier le code d'activation ci-dessous pour commencer à l'utiliser
        </Box>
        <Box pt={4} pb={4}>
          <AccountLicenseActivationToken
            activationToken={createdLicense?.activationToken}/>
        </Box>

        <Box  mb={3}>
          <Button onClick={this.closeDialog} variant="contained" color="primary">
            Retourner à mon espace
          </Button>
        </Box>
      </Box>
    )
  }

  static mapStateToProps = (state) => {
    return {
      dataStatus: selectAccountLicensesDataStatus(state),
      licenses: selectAccountLicenses(state),
    }
  };
}


export default connect(AccountLicensesAddNewButton.mapStateToProps)(
  withSnackbar(
    withTranslation(I18N_NS_STANDARDS)(
      AccountLicensesAddNewButton
    )
  )
);

