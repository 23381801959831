import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import YouTubeVideo from "./YouTubeVideo";
import map from "lodash/map";
import size from "lodash/size";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import {palette} from "../../modules/theme/theme";
import {BackBox, SelectableWhiteBox} from "../containers/Boxes";
import Grid from "@material-ui/core/Grid";

type VideoInfo = {
  id: string;
  thumbnailUrl: string;
  title: string;
}
type Props = {
  videosInfos: VideoInfo[];
};

const YouTubeList = ({videosInfos}: Props) => {
  const [currentVideoId, setCurrentVideoId] = useState(get(videosInfos, "[0].id"));
  const [autoplay, setAutoplay] = useState(false);

  const changeVideo = (id) => {
    setAutoplay(true)
    setCurrentVideoId(id);
  };
  const playNextVideo = () => {
    const curIndex = findIndex(videosInfos, vi => vi.id === currentVideoId);
    const nextIndex = (curIndex + 1 === videosInfos.length) ? 0 : curIndex + 1;
    setCurrentVideoId(videosInfos[nextIndex]?.id);
  };
  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={7}>

        <Box flex={1}>
          <YouTubeVideo videoId={currentVideoId} onEnd={playNextVideo} className="ytv-rspsv" opts={{
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              color: palette.primary.main,
              autoplay: autoplay ? 1 : 0,
            },
          }}/>
        </Box>
      </Grid>
      {size(videosInfos) > 1 &&
        <Grid item sm={12} md={5}>
          <BackBox flex={1} display="flex" flexDirection="column" p={2}
                   height={400} style={{overflowY: "auto"}}>
            {map(videosInfos, (video: VideoInfo) => (
              <Box key={video.id}>
                <SelectableWhiteBox mb={1}
                                    display="flex"
                                    width="100%"
                                    color="secondary.main"
                                    fontWeight="fontWeightMedium"
                                    onClick={() => changeVideo(video.id)}
                                    selected={currentVideoId === video.id}
                                    alignItems="center">
                  <img src={video.thumbnailUrl} style={{maxWidth: 100}} alt={"Amn Brains"}/>
                  <Box textAlign="start" p={1} pl={2}>{video.title}</Box>
                </SelectableWhiteBox>
              </Box>
            ))}
          </BackBox>
        </Grid>
      }
    </Grid>);
};

export default YouTubeList;

