import React, {Component} from 'react';

import Box from "@material-ui/core/Box";

import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IfFirebaseUnAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseUnAuthed";
import IfFirebaseAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseAuthed";
import FirebaseAuthConsumer from "amn/common/firebase/auth/react/consumers/FirebaseAuthConsumer";
import I18n from "amn/ui/i18n/I18n";
import Button from "@material-ui/core/Button";
import {I18N_NS_IAM} from "amn/common/i18n/I18nextConfig";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import {signOut} from "amn/common/firebase/auth/auth.actions";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Avatar from "@material-ui/core/Avatar";
import {openLoginDialog} from "amn/store/actions/iam/iam-ui.actions";
import Hidden from "@material-ui/core/Hidden";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import ButtonBase from "@material-ui/core/ButtonBase";
import {withRouter} from "react-router";


type Props = {};

type State = {};

class ButtonSignIn extends Component<Props, State> {


  state = {
    anchorEl: null,
    mobileMenuOpen: false
  }

  toggleMobileMenu = () => {
    this.setState({mobileMenuOpen: !this.state.mobileMenuOpen});

  };
  onOpenDesktopMenu = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  onCloseMenu = () => {
    this.setState({anchorEl: null});
  };

  signIn = () => {
    openLoginDialog();
  }

  signOut = async () => {
    try {
      // await signOut()
      this.props.history.push("/logout")
    } finally {
      this.onCloseMenu();
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>

        <IfFirebaseAuthed>
          <FirebaseAuthConsumer>
            {({isSignedIn, user, providerId}) => {
              return (
                <React.Fragment>
                  <Hidden smDown>
                    <IconButton edge="end"
                                color="primary"
                                onClick={this.onOpenDesktopMenu}>
                      {user?.photoURL ?
                        <Avatar alt={user?.displayName} src={user?.photoURL}
                                className={classes.avatar}/>
                        :
                        <AccountCircleOutlinedIcon/>
                      }
                    </IconButton>
                  </Hidden>
                  <Hidden mdUp>
                    <Box mt={2} mb={1}>
                      <ButtonBase onClick={this.toggleMobileMenu}>
                        <Box fontSize="subtitle1.fontSize"
                             color="secondary.main"
                             display="flex"
                             alignItems="center">
                          {user?.photoURL ?
                            <Avatar alt={user?.displayName} src={user?.photoURL}
                                    className={classes.avatar}/>
                            :
                            <AccountCircleOutlinedIcon/>
                          }
                          {/*<Hidden xsDown> <Box ml={1} style={{wordBreak: "break-word"}}>{user?.displayName}</Box></Hidden>*/}
                          <Box ml={1}>
                            {this.state.mobileMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                          </Box>
                        </Box>
                      </ButtonBase>
                      <Collapse in={this.state.mobileMenuOpen} timeout="auto" unmountOnExit>
                        <Box mt={2} mb={1}>
                          <ButtonBase onClick={this.signOut}>
                            <Box fontSize="subtitle1.fontSize"
                                 color="secondary.main"
                                 display="flex"
                                 alignItems="center">
                              <ExitToAppOutlinedIcon/>
                              <Box ml={1}>
                                <I18n ns={I18N_NS_IAM} i18nKey="SignOut">
                                  SignOut
                                </I18n>
                              </Box>
                            </Box>
                          </ButtonBase>
                        </Box>
                      </Collapse>
                    </Box>
                  </Hidden>
                </React.Fragment>

              );
            }}
          </FirebaseAuthConsumer>
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          <Hidden mdUp>
            <Box mt={2}/>
          </Hidden>
          <Box color={"primary.contrastText"}>
            <Button onClick={this.signIn}
                    className={classes.button}
                    color={"primary"}
                    startIcon={<PersonOutlineOutlinedIcon/>}>
              <I18n ns={I18N_NS_IAM}>
                signIn
              </I18n>
            </Button>
          </Box>
          <Hidden mdUp>
            <Box mb={1}/>
          </Hidden>
        </IfFirebaseUnAuthed>
        {
          this.renderMenu()
        }
      </React.Fragment>
    );
  }

  renderMenu = () => {
    const {anchorEl} = this.state;
    return (
      <Hidden smDown>
        <Menu anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={!!anchorEl}
              onClose={this.onCloseMenu}>
          {/*<MenuItem onClick={this.onCloseMenu}>Profile</MenuItem>*/}
          <MenuItem onClick={this.signOut}>
            <I18n ns={I18N_NS_IAM} i18nKey="SignOut">
              SignOut
            </I18n>
          </MenuItem>
        </Menu>
      </Hidden>
    )
  }
}


const styles = (theme) => ({

  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  logo: {
    height: "56px",
    margin: "4px"
  },
  button: {
    // color : theme.palette.primary.contrastText
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
})

export default withStyles(styles)(withRouter(ButtonSignIn));



