import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import {Breadcrumb} from "amn/ui/containers/Breadcrumbs";
import PortalPaths from "amn/modules/common/routes/PortalPaths";


export const AccountSubscriptionsListBreadcrumb = ({active}) => (
  /*TODO i18n*/
  <Breadcrumb to={PortalPaths.accountLicensesUrl()}
              icon={<PeopleOutlineOutlinedIcon fontSize="small"/>}
              label="Abonnements"
              active={active}/>
);


export const AccountBreadcrumbs = ({children, onDashboardClick}) => {

  return (
    <Breadcrumbs separator="›">
      {/*<DashboardBreadcrumb onClick={onDashboardClick}/>*/}
      {children}

    </Breadcrumbs>
  )
}
