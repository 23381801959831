import React from "react";

export type AuthState = {
  isSignedIn: boolean;
  providerId: ("none" | "google.com" | string) | null;
  user: any;
  firebase: any;
};

const firebaseAuthProviderDefaultProps: AuthState = {
  isSignedIn: false,
  providerId: null,
  user: null,
  firebase: {}
};

const FirebaseAuthContext = React.createContext(firebaseAuthProviderDefaultProps);
const {Provider, Consumer} = FirebaseAuthContext;


export default FirebaseAuthContext;

