import client from "../common/RestAuthClient";
import type {SubscriptionPlan} from "../../../model/account/Subscription";


const SubscriptionsApi = {

  getAccountSubscriptions: async () => {
    return client.get("/api/subscriptions");
  },

  getAccountSubscription: async (subscriptionId: string) => {
    return client.get(`/api/subscriptions/${subscriptionId}`);
  },

  createSubscription: async (plan: SubscriptionPlan) => {
    return client.post(`/api/subscriptions/`, {data: {plan}});
  },

  completeSubscriptionCheckout: async (subscriptionId: string) => {
    return client.post(`/api/subscriptions/${subscriptionId}/actions/complete-checkout`);
  },

  createUpdateSubscriptionCheckout: async (subscriptionId: string) => {
    return client.post(`/api/subscriptions/${subscriptionId}/actions/checkout-for-update`);
  },

  createAddSubscriptionCheckout: async (plan) => {
    return client.post(`/api/subscriptions/checkout`, {data: {plan}});
  }
}


export default SubscriptionsApi;