import get from "lodash/get";
import memoize from "lodash/memoize";
import {createSelector} from 'reselect';
import type {LookupTable} from "amn/model/common/Divers";
import {LookupFn} from "amn/model/common/Divers";
import type {DataStatus} from "amn/store/reducers/common";
import type {Subscription} from "amn/model/account/Subscription";

export const selectAccountSubscriptionsState = (state) => get(state, 'account.subscriptions');

export const selectAccountSubscriptions = createSelector(
  selectAccountSubscriptionsState,
  (state): LookupTable<Subscription> => get(state, 'entities', {})
);

export const selectAccountSubscriptionsDataStatus = createSelector(
  selectAccountSubscriptionsState,
  (state): DataStatus => get(state, 'status')
);


export const makeAccountSubscriptionSelector = createSelector(
  selectAccountSubscriptions,
  (subscriptions: LookupTable<Subscription> = {}): LookupFn<Subscription> => memoize(
    (id) => subscriptions[id]
  )
);
