import React from 'react';
import styled from 'styled-components';
import Layout, {getContent, getDrawerSidebar, getHeader, Root} from '@mui-treasury/layout';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import PortalSidebar from "./PortalSidebar";
import PortalHeader from "./PortalHeader";


const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const Content = getContent(styled);


const scheme = Layout();

scheme.configureHeader(builder => {
  builder.create("portalHeader")
    .registerConfig('xs', {
      position: 'fixed',
      clipped: true,
      initialHeight: 64,
    });
});

scheme.configureEdgeSidebar(builder => {
  builder
    .create('portalSidebar', {anchor: 'left'})
    .registerPermanentConfig('xs', {
      width: 256,
      collapsible: true,
      collapsedWidth: 72,
      autoExpanded: true
    });
});


const useDrawerStyles = makeStyles(() => ({
  paper: {
    border: 'none',
    overflow: 'visible',
  }
}))

const useContainerStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#F5F6F8"
  }
}))


const PortalLayout = ({children}) => {
  const drawerStyles = useDrawerStyles();
  const containerStyles = useContainerStyles();

  return (
    <Root scheme={scheme}
          themeProviderOmitted
          initialState={{sidebar: {portalSidebar: {collapsed: true}}}}>
      <Header>
        <PortalHeader/>
      </Header>

      <DrawerSidebar sidebarId={'portalSidebar'}
                     classes={drawerStyles}>
        <PortalSidebar/>
      </DrawerSidebar>
      <Content classes={containerStyles}>
        <Box mt={2} p={3} pb={12}>
          <Container maxWidth="xl">
            {children}
          </Container>
        </Box>
      </Content>
    </Root>
  );
};

export default PortalLayout;
