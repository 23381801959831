const Config = {
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
  GTM_ID: process.env.REACT_APP_GTM_ID,
  CHARGEBEE_SITE: process.env.REACT_APP_CHARGEBEE_SITE,//"amnbrains-test",
  CHARGEBEE_PUBLISHABLE_KEY: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,//"test_iZcukPsmDKOnZcAqAwlmRsJuyy8vKwktZ"
  AMN_AUDITOR: {
    INSTALLER_LINK: process.env.REACT_APP_AMN_AUDITOR_INSTALLER_LINK,
    PORTABLE_LINK: process.env.REACT_APP_AMN_AUDITOR_PORTABLE_LINK
  }
};


export default Config;
