import React from "react";
import FirebaseAuthContext, {AuthState} from "../FirebaseAuthContext";
import {renderAndAddProps} from "render-and-add-props";

const FirebaseAuthConsumer = ({children}) => {
  return (
    <FirebaseAuthContext.Consumer>
      {(authState: AuthState) => {
        return renderAndAddProps(children, authState);
      }}
    </FirebaseAuthContext.Consumer>
  );
};

export default FirebaseAuthConsumer;