import React, {Component} from 'react';
import type {License, Subscription} from "amn/model/account/Subscription";
import {
  renderSubscriptionPrice,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_IN_TRIAL
} from "amn/model/account/Subscription";
import Box from "@material-ui/core/Box";
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import {makeAccountSubscriptionSelector} from "amn/store/selectors/account/account-subscriptions.selectors";
import {connect} from "react-redux";
import {BackBox, BorderedBox, WhiteBox} from "amn/ui/containers/Boxes";
import AccountSubscriptionStatus from "./AccountSubscriptionStatus";
import {getChargebeeInstance, openPortalSection, PORTAL_SECTION_SUBSCRIPTION_DETAILS} from "amn/common/chargebee";
import SubscriptionsApi from "amn/backend/api/portal/SubscriptionsApi";
import AccountSubscriptionsManager from "amn/store/actors/account/AccountSubscriptionsManager";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {DATE_FNS_LOCALES, DATE_FORMAT_DATE_WORDS, formatDate, formatDistanceToNow} from "amn/common/utils/DateUtils";
import CollapsiblePanel from "amn/ui/containers/CollapsiblePanel";
import CopyToClipboardIconButton from "amn/ui/diverse/CopyToClipboardIconButton";
import {IconButton} from "@material-ui/core";
import {Tooltip} from "amn/ui/diverse/ToolTips";
import ContractIcon from "amn/ui/icons/ContractIcon";
import {Alert, AlertTitle} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import ClockAlertIcon from "amn/ui/icons/ClockAlertIcon";
import AccountLicenseActivationToken from "./AccountLicenseActivationToken";
import SubscriptionScheduledIcon from "amn/ui/icons/SubscriptionScheduledIcon";
import Grid from "@material-ui/core/Grid";
import InlineEditableTextField from "amn/ui/forms/InlineEditableTextField";
import {patchLicense} from "amn/store/actions/account/account-licenses.actions";
import isEmpty from "lodash/isEmpty";
import type {ErrorItem} from "amn/model/common/Responses";
import {withSnackbar} from "notistack";
import {withTranslation} from "react-i18next";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import WithBreakpoints from "amn/ui/containers/WithBreakpoints";


type Props = {
  license: License;
  subscription: Subscription;
};

class AccountLicense extends Component<Props> {

  openUpdateSubscriptionPopup = async () => {
    const {subscription} = this.props;
    if (subscription) {
      const data = await SubscriptionsApi.createUpdateSubscriptionCheckout(subscription?.id);

      getChargebeeInstance().openCheckout({
        hostedPage: () => Promise.resolve(data),
        success: (hostedPageId) => AccountSubscriptionsManager.reloadAccountSubscription(subscription?.id)
      });
    }
  }

  openSubscriptionDetailsPopup = async () => {
    const {subscription} = this.props;
    if (subscription) {
      openPortalSection(
        PORTAL_SECTION_SUBSCRIPTION_DETAILS,
        {subscriptionId: subscription.id},
        {
          close: () => AccountSubscriptionsManager.reloadAccountSubscription(subscription?.id)
        }
      )
    }
  }

  update = async (fieldName, value) => {
    try {
      const {license} = this.props;
      await patchLicense(license.id, {[fieldName]: value});
    } catch (ex) {
      const {enqueueSnackbar} = this.props;
      if (!isEmpty(ex?.error?.errors)) {
        const error: ErrorItem = ex.error.errors[0];
        enqueueSnackbar(error?.title, {variant: 'error'});
      } else {
        enqueueSnackbar("Une erreur est survenue de la création de votre license d'essai", {variant: 'error'});
      }
    }
  }

  render() {
    const {license, subscription} = this.props;
    return (
      <WithBreakpoints>
        {(bps) =>
          (
            <BorderedBox p={3} pr={2}>
              <CollapsiblePanel initiallyOpen={false} title={this.renderHeader()}
                                getTooltipTitle={(open) => open ? "Masquer les détails" : "Afficher les détails"}>

                <BackBox mt={2} p={2} display="flex">
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} lg={6}>
                      <WhiteBox p={3} flex={1}>
                        <Box display="flex" alignItems="center">
                          <Box mr={1} display="flex" alignItems="center">
                            <SubscriptionScheduledIcon fontSize="small" color="primary"/>
                          </Box>
                          <Box flex={1} fontWeight="fontWeightMedium" color="primary.main">
                            Mon Abonnement
                          </Box>

                        </Box>
                        <Box mt={2} display="flex">
                          <Box flex={2} fontWeight="fontWeightMedium" color="secondary.main">
                            Date de création
                          </Box>
                          <Box flex={2}>
                            {formatDate(subscription?.creationDate, DATE_FORMAT_DATE_WORDS, {locale: DATE_FNS_LOCALES.fr})}
                          </Box>
                        </Box>
                        {subscription?.status === SUBSCRIPTION_STATUS_IN_TRIAL &&
                          <Box mt={1} display="flex">
                            <Box flex={2} fontWeight="fontWeightMedium" color="secondary.main">
                              En essai jusqu'au
                            </Box>
                            <Box flex={2}>
                              {formatDate(subscription?.trialEndDate, DATE_FORMAT_DATE_WORDS, {locale: DATE_FNS_LOCALES.fr})}
                            </Box>
                          </Box>
                        }
                        {subscription?.nextBillingDate ?
                          <Box mt={1} display="flex">
                            <Box flex={2} fontWeight="fontWeightMedium" color="secondary.main">
                              Date de la prochaine Facturation
                            </Box>
                            <Box flex={2}>
                  <span>
                  {formatDate(subscription?.nextBillingDate, DATE_FORMAT_DATE_WORDS, {locale: DATE_FNS_LOCALES.fr})}
                  </span>
                              &nbsp;-&nbsp;
                              <Box component={"span"} fontWeight="fontWeightMedium"
                                   color="secondary.main">{renderSubscriptionPrice(subscription)} HT</Box>
                            </Box>
                          </Box>
                          :
                          <Box mt={1} display="flex">
                            <Box flex={2} fontWeight="fontWeightMedium" color="secondary.main">
                              Fin de l'abonnement programmée
                            </Box>
                            <Box flex={2}>
                  <span>
                  {formatDate(subscription?.currentTermEndDate, DATE_FORMAT_DATE_WORDS, {locale: DATE_FNS_LOCALES.fr})}
                  </span>
                            </Box>
                          </Box>
                        }
                        <Box mt={3} display="flex" justifyContent="flex-end">
                          <Button color="primary"
                                  variant="outlined"
                                  startIcon={bps.mdUp ? <SettingsOutlinedIcon/> : null}
                                  size="small" onClick={this.openSubscriptionDetailsPopup}>
                            Gérer l'abonnement
                          </Button>
                        </Box>
                      </WhiteBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <WhiteBox p={3} flex={1}>
                        <Box display="flex" alignItems="center">
                          <Box mr={1} display="flex" alignItems="center">
                            <ContractIcon fontSize="small" color="primary"/>
                          </Box>
                          <Box fontWeight="fontWeightMedium" color="primary.main">
                            Licence
                          </Box>
                        </Box>
                        <Box mt={2}>
                          <Box fontWeight="fontWeightMedium" color="secondary.main">
                            Code d'Activation
                          </Box>
                          <Box mt={1}>
                            <AccountLicenseActivationToken secret activationToken={license?.activationToken}/>
                          </Box>
                        </Box>
                      </WhiteBox>
                    </Grid>
                    {/*TODO*/}
                    {/*<Box>*/}
                    {/*  Méthode de paiement : ???*/}
                    {/*</Box>*/}
                    {/*<Box>*/}
                    {/*  Billing info : ??*/}
                    {/*</Box>*/}
                  </Grid>
                </BackBox>
              </CollapsiblePanel>

              {subscription?.status === SUBSCRIPTION_STATUS_IN_TRIAL && !subscription?.paymentSourceId &&

                <Box mt={2}>
                  <Box flex={1}>
                    <Alert severity="warning">
                      <AlertTitle>Informations de Paiement manquantes</AlertTitle>
                      <Box mt={2}>
                        {/*Votre license est actuellement en période d'essai. <br/>*/}
                        Vous n'avez pas encore renseigné vos informations de paiement pour cette licence. <br/>
                        <b>Il vous reste {this.renderTrialDistance()}</b> &nbsp;
                        avant la fin de votre période d'essai. <br/>
                        Si à la fin de cette période, vos informations de paiement ne sont toujours pas complètes,
                        votre licence sera suspendue et vous ne pourrez plus utiliser l'application.
                      </Box>
                      <Box display="flex" justifyContent="flex-end" mt={2} mb={1}>
                        <Button color="primary"
                                onClick={this.openUpdateSubscriptionPopup}
                                variant="contained">
                          Compléter mes informations
                        </Button>
                      </Box>
                    </Alert>
                  </Box>

                </Box>
              }

              {subscription?.status === SUBSCRIPTION_STATUS_CANCELLED && !subscription?.paymentSourceId &&

                <Box p={bps.mdUp ? 3 : 0}>
                  <Box flex={1}>
                    <Alert severity="warning" className="wide">
                      <AlertTitle>Informations de Paiement manquantes</AlertTitle>
                      <Box mt={2}>
                        Vous n'avez pas renseigné vos informations de paiement pour cette licence. <br/>
                        Pour réactiver cette Licence, veuillez compléter vos informations.
                      </Box>
                      <Box display="flex" justifyContent="flex-end" mt={2} mb={1}>
                        <Button color="primary"
                                onClick={this.openSubscriptionDetailsPopup}
                                variant="contained">
                          Compléter et Réactiver
                        </Button>
                      </Box>

                    </Alert>
                  </Box>

                </Box>
              }

            </BorderedBox>
          )}
      </WithBreakpoints>
    );
  }

  renderHeader = () => {
    const {license, subscription} = this.props;
    return (
      <WithBreakpoints>
        {(bps) =>
          (
            <Box>
              <Grid container spacing={2}>
                {/*<Box display="flex" mr={2} alignItems="center"  flexDirection={bps.mdUp ? "row" : "column"}>*/}
                <Grid item xs={bps.mdUp ? 6 : 12}>
                  <Box flex={1} mr={2} display={"flex"} alignItems="center">
                    <Box mr={2} display="flex" alignItems="center">
                      <ContractIcon color="secondary" style={bps.mdUp ? {fontSize: 40} : {fontSize: 24}}/>
                    </Box>
                    <Box flex={1}
                         display="flex"
                         alignItems="center"
                         fontWeight="fontWeightMedium"
                         fontSize={bps.mdUp ? "subtitle1.fontSize" : "body2.fontSize"}
                         color="secondary.main">
                      <Box flex={1}>
                        <Box>

                          <InlineEditableTextField value={license?.name}
                                                   TextComponent={Box}
                                                   textProperties={{
                                                     fontSize: bps.mdUp ? "subtitle1.fontSize" : "body2.fontSize",
                                                     color: "secondary.main"
                                                   }}
                                                   onSubmit={(value) => this.update("name", value)}
                                                   textFieldProperties={{fullWidth: true}}/>
                          {/*<Box fontSize="subtitle1.fontSize" color="secondary.main">*/}
                          {/*  {license?.name}*/}
                          {/*</Box>*/}

                        </Box>
                        <Box color="text.secondary"
                             fontSize={bps.mdUp ? "caption.fontSize" : 10}>
                          {license?.id}
                        </Box>
                      </Box>
                    </Box>


                  </Box>
                </Grid>

                <Grid item xs={bps.mdUp ? 6 : 12}>
                  <Box display="flex" flexDirection={"row"}>
                    {subscription?.status === SUBSCRIPTION_STATUS_IN_TRIAL && !subscription?.paymentSourceId &&
                      <Tooltip title={`Votre période d'essai se termine dans ${this.renderTrialDistance()}.`}>
                        <Box mr={1}
                             mt={0.5}
                             display="flex"
                             alignItems="center"
                             color="warning.main"
                             justifyContent="center">
                          <Box mr={1}>
                            <ClockAlertIcon/>
                          </Box>
                          <Box fontSize="caption.fontSize"
                               color="secondary.main">
                            Il vous reste <br/>
                            {this.renderTrialDistance()}
                          </Box>
                        </Box>
                      </Tooltip>
                    }
                    {subscription?.status === SUBSCRIPTION_STATUS_IN_TRIAL && subscription?.paymentSourceId &&
                      <Tooltip
                        title={`Votre licence sera renouvelée automatiquement à la fin de la période d'essai qui se termine dans ${this.renderTrialDistance()}.`}>
                        <Box mr={1}
                             mt={0.5}
                             display="flex"
                             alignItems="center"
                             justifyContent="center">
                          <Box mr={1}>
                            <AutorenewOutlinedIcon color="secondary"/>
                          </Box>
                          <Box fontSize="caption.fontSize"
                               color="secondary.main">
                            Renouvellement<br/>
                            Automatique
                          </Box>
                        </Box>
                      </Tooltip>
                    }
                    <Box mr={1}>
                      <Tooltip title={"Gestion de l'abonnement"}>
                        <IconButton color="secondary" onClick={this.openSubscriptionDetailsPopup}>
                          <SettingsOutlinedIcon/>
                        </IconButton>
                      </Tooltip>

                    </Box>
                    <Box mr={2}>
                      <CopyToClipboardIconButton color="secondary"
                                                 anchorOrigin={{
                                                   vertical: 'bottom',
                                                   horizontal: 'center',
                                                 }}
                                                 tooltip="Copier le code d'activation de la licence"
                                                 successMessage="Le code d'activation a été copié"
                                                 value={license?.activationToken}/>
                    </Box>
                    <Box display={"flex"} justifyContent="flex-end">
                      <AccountSubscriptionStatus status={subscription?.status}/>
                    </Box>
                  </Box>
                </Grid>


                {/**********************/}
                {/*<Box alignItems="flex-end" flexDirection="column">*/}
                {/*  <img src={require("assets/images/logo/amn-auditor.svg")}*/}
                {/*       style={{maxHeight: 24, minWidth: 100}}*/}
                {/*       alt="Amn Brains"/>*/}
                {/*  <Box mt={0.5} fontSize="caption.fontSize">*/}
                {/*    <Link href={Config.AMN_AUDITOR.INSTALLER_LINK} target="_blank">Télécharger l'application</Link>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                {/**********************/}


                {/*</Box>*/}
              </Grid>
            </Box>)}
      </WithBreakpoints>
    )
  }

  renderTrialDistance = () => {
    const {subscription} = this.props;
    return formatDistanceToNow(
      new Date(subscription?.trialEndDate),
      {locale: DATE_FNS_LOCALES.fr}
    ) || "Dans quelques jours";
  }

  static mapStateToProps = (state, props) => {
    const getSubscription = makeAccountSubscriptionSelector(state);
    const subscription = getSubscription(props.license?.subscriptionId);
    return {
      subscription
    }
  };
}


export default connect(AccountLicense.mapStateToProps)(
  withSnackbar(
    withTranslation(I18N_NS_STANDARDS)(AccountLicense)
  )
);