import React, {useState} from 'react';
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import Button, {ButtonProps} from "@material-ui/core/Button";
import copy from "clipboard-copy";
import {Snackbar} from "@material-ui/core";
import {SnackbarOrigin} from "@material-ui/core/Snackbar/Snackbar";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";


type Props = {
  value: string;
  label?: React.ReactNode;
  successMessage?: React.ReactNode;
  anchorOrigin: SnackbarOrigin;
} & ButtonProps;

const CopyToClipboardButton = ({
                                 value,
                                 label = "Copier",
                                 successMessage = "Copié",
                                 anchorOrigin,
                                 onClick,
                                 ...props
                               }: Props
) => {

  const [open, setOpen] = useState(false);

  const handleClick = async (event) => {
    if (value) {
      try {
        await copy(value);
        setOpen(true);
      } finally {

      }
    }
    onClick && onClick(event);
  }
  return (
    <>
      <Button {...props}
              onClick={handleClick}
              startIcon={ <FileCopyOutlinedIcon/>}>
        {label}
      </Button>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={successMessage}
      />
    </>
  );
};

export default CopyToClipboardButton;