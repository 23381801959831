import {createSelector} from 'reselect';
import get from "lodash/get";

export const selectUserState = (state) => get(state, 'iam.user');

export const selectUser = createSelector(
  selectUserState,
  (state) => get(state, 'entity')
);

export const selectUserDataStatus = createSelector(
  selectUserState,
  (state) => get(state, 'status')
);

