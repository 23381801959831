import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export default class RestClient {

  axios: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.axios = axios.create(config);
  }

  get = async <T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> => {
    return this.request(url, "get", config);
  }

  post = async <T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> => {
    return this.request(url, "post", config);
  }

  patch = async <T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> => {
    return this.request(url, "patch", config);
  }

  delete = async <T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> => {
    return this.request(url, "delete", config);
  }

  request = async <T, D>(url: string, method: string, config: AxiosRequestConfig<D> = {}): Promise<AxiosResponse<T, D>> => {
    try {
      const {data} = await this.axios.request({...config, url, method});
      return data;
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError = (error) => {
    if (error?.response) {
      const data = error.response.data;
      throw data || [{
        title: "Erreur Inconnue. Merci de nous contacter.",
        type: "UNKNOWN_ERROR",
        code: "UNKNOWN_ERROR"
      }];
    } else {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      // Or
      // Something happened in setting up the request that triggered an Error
      throw [{
        title: "Erreur Technique. Merci de nous contacter.",
        type: "REQUEST_ERROR",
        code: "REQUEST_ERROR"
      }];
    }
  }

}