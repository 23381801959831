import store from "amn/store";
import type {ActionPromise} from "../common";
import type {ListResponse} from "amn/model/common/Responses";
import SubscriptionsBackendAdapter from "amn/backend/adapters/SubscriptionsBackendAdapter";
import type {CreateSubscriptionResponse, Subscription, SubscriptionPlan} from "amn/model/account/Subscription";


export const LOAD_ACCOUNT_SUBSCRIPTIONS_REQUEST = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTIONS_REQUEST';
export const LOAD_ACCOUNT_SUBSCRIPTIONS_SUCCESS = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTIONS_SUCCESS';
export const LOAD_ACCOUNT_SUBSCRIPTIONS_ERROR = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTIONS_ERROR';


export type LoadAccountSubscriptionsAction = ActionPromise<{}, ListResponse<Subscription>>;

export function loadAccountSubscriptions(): LoadAccountSubscriptionsAction {
  return store.dispatch({
    types: [LOAD_ACCOUNT_SUBSCRIPTIONS_REQUEST, LOAD_ACCOUNT_SUBSCRIPTIONS_SUCCESS, LOAD_ACCOUNT_SUBSCRIPTIONS_ERROR],
    promise: SubscriptionsBackendAdapter.loadAccountSubscriptions()
  });
}

export const LOAD_ACCOUNT_SUBSCRIPTION_REQUEST = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTION_REQUEST';
export const LOAD_ACCOUNT_SUBSCRIPTION_SUCCESS = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTION_SUCCESS';
export const LOAD_ACCOUNT_SUBSCRIPTION_ERROR = 'app/standards/LOAD_ACCOUNT_SUBSCRIPTION_ERROR';

export type LoadAccountSubscriptionAction = ActionPromise<{ id: string }, Subscription>;

export function loadAccountSubscription(id: string): LoadAccountSubscriptionAction {
  return store.dispatch({
    types: [LOAD_ACCOUNT_SUBSCRIPTION_REQUEST, LOAD_ACCOUNT_SUBSCRIPTION_SUCCESS, LOAD_ACCOUNT_SUBSCRIPTION_ERROR],
    id,
    promise: SubscriptionsBackendAdapter.loadAccountSubscription(id)
  });
}


export const CREATE_ACCOUNT_SUBSCRIPTION_REQUEST = 'app/standards/CREATE_ACCOUNT_SUBSCRIPTION_REQUEST';
export const CREATE_ACCOUNT_SUBSCRIPTION_SUCCESS = 'app/standards/CREATE_ACCOUNT_SUBSCRIPTION_SUCCESS';
export const CREATE_ACCOUNT_SUBSCRIPTION_ERROR = 'app/standards/CREATE_ACCOUNT_SUBSCRIPTION_ERROR';

export type CreateAccountSubscriptionAction = ActionPromise<{}, CreateSubscriptionResponse>;

export function createAccountSubscription(plan: SubscriptionPlan): CreateAccountSubscriptionAction {
  return store.dispatch({
    types: [CREATE_ACCOUNT_SUBSCRIPTION_REQUEST, CREATE_ACCOUNT_SUBSCRIPTION_SUCCESS, CREATE_ACCOUNT_SUBSCRIPTION_ERROR],
    promise: SubscriptionsBackendAdapter.createAccountSubscription(plan)
  });
}


export const COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_REQUEST = 'app/standards/COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_REQUEST';
export const COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_SUCCESS = 'app/standards/COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_SUCCESS';
export const COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_ERROR = 'app/standards/COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_ERROR';

export type CompleteSubscriptionCheckoutAction = ActionPromise<{ subscriptionId: string }, CreateSubscriptionResponse>;

export function completeSubscriptionCheckout(subscriptionId: string): CompleteSubscriptionCheckoutAction {
  return store.dispatch({
    types: [COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_REQUEST, COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_SUCCESS, COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_ERROR],
    subscriptionId,
    promise: SubscriptionsBackendAdapter.completeSubscriptionCheckout(subscriptionId)
  });
}

