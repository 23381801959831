import React, {Component} from 'react';
import IfFirebaseUnAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseUnAuthed";
import IfFirebaseAuthed from "amn/common/firebase/auth/react/consumers/IfFirebaseAuthed";
import LoginForm from "../components/LoginForm";
import {Redirect} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

type Props = {
  title?: string;
  location?: {
    pathname: string;
    state: {
      from: any;
    }
  }
};

class LoginScreen extends Component<Props> {

  onLogin = () => {

  }

  render() {
    const {from} = this.props.location?.state || {from: {pathname: "/"}};

    return (
      <div>
        <IfFirebaseAuthed>
          <>
            <Redirect to={from}/>
          </>
        </IfFirebaseAuthed>
        <IfFirebaseUnAuthed>
          <Box mt={14}>
            <Container>
              <LoginForm onLoginSuccess={this.onLogin}
                         config={{languageDictionary: {title: this.props.title}}}/>
            </Container>
          </Box>

        </IfFirebaseUnAuthed>
      </div>
    );
  }
}

export default LoginScreen;