import React from 'react';
import {useRowGutterStyles} from '@mui-treasury/styles/gutter/row';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import MenuIcon from "@material-ui/icons/Menu";
import {getCollapseIcon} from "@mui-treasury/layout";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import ButtonSignIn from "../../../common/components/layout/primary/ButtonSignIn";
import {Link} from "react-router-dom";
import WithBreakpoints from "../../../../ui/containers/WithBreakpoints";

const CollapseIcon = getCollapseIcon(styled);


const PortalHeader = ({collapse}) => {
  const styles = useStyles();
  // const actionStyles = useSizedIconButtonStyles({padding: 8, childSize: 24});
  const tinyGutterStyles = useRowGutterStyles({
    size: 2,
    before: 10,
  });
  return (
    <WithBreakpoints>
      {(bps) =>
        (
          <Toolbar className={styles.toolbar}>

            <Box width={280} display={'flex'} alignItems="center">
              {bps.mdUp && <CollapseIcon sidebarId={'portalSidebar'} className={styles.collapse}>
                {() => <MenuIcon color="secondary"/>}
              </CollapseIcon>}

              {/*<img className={styles.logo} alt="" src={require("./logo.svg")}/>*/}
              <Link to="/">
                <img src={require("assets/images/logo/amn-brains-chars.png")}
                     alt={"Amn Brains Logo"}
                     className={styles.logo}/>
              </Link>
            </Box>
            <Box flex={2}>

            </Box>
            <Box justifyContent="flex-end" display="flex" flex={1} className={tinyGutterStyles.parent}>
              <ButtonSignIn/>
            </Box>

          </Toolbar>
        )}</WithBreakpoints>
  );
};


const useStyles = makeStyles({
  collapse: {
    marginLeft: -12,
    marginRight: 4,
  },
  logo: {
    width: 152,
    marginLeft: 16,
  },
  toolbar: {
   // minWidth: 768,
    minHeight: '64px !important',
    backgroundColor: '#fff',
    boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)',
  },
  searchInput: {
    backgroundColor: '#f1f3f4',
    height: 48,
    borderRadius: 8,
    padding: '0 8px',
    maxWidth: 720,
    flexGrow: 1,
  },
});

export default PortalHeader;
