export const hideCharacters = (str: string, nbCharsToShow: number = 4): string => {
  const hideNum = [];
  for (let i = 0; i < str.length; i++) {
    if (i < str.length - nbCharsToShow) {
      hideNum.push("x");
    } else {
      hideNum.push(str[i]);
    }
  }
  return hideNum.join("");
};