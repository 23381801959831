import React from 'react';
import styled from 'styled-components';
import {useTheme} from "@material-ui/core/styles";
import {getSidebarContent, useSidebarCollapse, useSidebarCtx,} from '@mui-treasury/layout';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SidebarNavItem from "amn/ui/navigation/SidebarNavItem";
import PortalPaths from "../../../common/routes/PortalPaths";
import SecurityWarningIcon from "amn/ui/icons/SecurityWarningIcon";
import InvoiceIcon from "amn/ui/icons/InvoiceIcon";
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import SidebarItem from "amn/ui/navigation/SidebarItem";
import HandCardIcon from "amn/ui/icons/HandCardIcon";
import {
  openPortalSection,
  PORTAL_SECTION_ACCOUNT_DETAILS,
  PORTAL_SECTION_ADDRESS,
  PORTAL_SECTION_BILLING_HISTORY,
  PORTAL_SECTION_PAYMENT_SOURCES
} from "amn/common/chargebee";
import ContractIcon from "../../../../ui/icons/ContractIcon";


const SidebarContent = getSidebarContent(styled);


const PortalSidebar = () => {
  const {expanded} = useSidebarCtx();
  const {state} = useSidebarCollapse();
  const theme = useTheme();
  const collapsed = !expanded && state.collapsed;

  return (
    <Box display={'flex'}
         flexGrow={1}
         flexDirection={'column'}>
      <Box mt={6}/>
      <SidebarContent style={{overflow: "unset"}}>
        <Box maxWidth={240}>
          <List>
            <>

              <SidebarNavItem to={"/"}
                              exact
                              label={"Accueil"}
                              startIcon={<HomeOutlinedIcon/>}
                              collapsed={collapsed}/>

              <SidebarNavItem to={PortalPaths.accountLicensesUrl()}
                              color={theme.palette.primary}
                              label={"Mes Licences"}
                              startIcon={<ContractIcon/>}
                              collapsed={collapsed}/>

              <SidebarItem onClick={() => openPortalSection(PORTAL_SECTION_PAYMENT_SOURCES)}
                           color={theme.palette.primary}
                           label="Méthodes de Paiement"
                           startIcon={<HandCardIcon/>}
                           collapsed={collapsed}/>

              <SidebarItem onClick={() => openPortalSection(PORTAL_SECTION_BILLING_HISTORY)}
                           color={theme.palette.primary}
                           label="Factures"
                           startIcon={<InvoiceIcon/>}
                           collapsed={collapsed}/>


              <SidebarItem onClick={() => openPortalSection(PORTAL_SECTION_ADDRESS)}
                           color={theme.palette.primary}
                           label="Adresse de facturation"
                           startIcon={<ContactMailOutlinedIcon/>}
                           collapsed={collapsed}/>


            </>
          </List>
        </Box>
      </SidebarContent>
      {/*<Divider/>*/}
      {/*<Box maxWidth={240} pb={3} pt={1.5}>*/}
      {/*  <List*/}
      {/*    // subheader={*/}
      {/*    //   !collapsed && (*/}
      {/*    //     <Box ml={'26px'} mr={'12px'} mb={1}>*/}
      {/*    //       <b>Meet</b>{' '}*/}
      {/*    //       <Box*/}
      {/*    //         ml={0.5}*/}
      {/*    //         display="inline-flex"*/}
      {/*    //         px={0.5}*/}
      {/*    //         bgcolor="#1a73e8"*/}
      {/*    //         color="#fff"*/}
      {/*    //         fontSize="0.75rem"*/}
      {/*    //         borderRadius={4}*/}
      {/*    //       >*/}
      {/*    //         New*/}
      {/*    //       </Box>*/}
      {/*    //     </Box>*/}
      {/*    //   )*/}
      {/*    // }*/}
      {/*  >*/}


      {/*    /!*<SidebarItem onClick={() => openPortalSection(PORTAL_SECTION_ACCOUNT_DETAILS)}*!/*/}
      {/*    /!*             color={theme.palette.primary}*!/*/}
      {/*    /!*             label="Parmètres régionaux"*!/*/}
      {/*    /!*             startIcon={<SettingsApplicationsOutlinedIcon/>}*!/*/}
      {/*    /!*             collapsed={collapsed}/>*!/*/}

      {/*    <SidebarNavItem to={"/"}*/}
      {/*                    exact*/}
      {/*                    label={"Accueil"}*/}
      {/*                    startIcon={<HomeOutlinedIcon/>}*/}
      {/*                    collapsed={collapsed}/>*/}

      {/*  </List>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default PortalSidebar;
