import {createSelector} from 'reselect';
import get from "lodash/get";

export const selectAccountCustomerInfoState = (state) => get(state, 'account.customerInfo');

export const selectAccountCustomerInfo = createSelector(
  selectAccountCustomerInfoState,
  (state) => get(state, 'entity')
);

export const selectAccountCustomerInfoDataStatus = createSelector(
  selectAccountCustomerInfoState,
  (state) => get(state, 'status')
);

