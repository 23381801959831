import {DATA_LOADED} from "../../reducers/common";
import store from "amn/store";
import firebaseAuth from "amn/common/firebase/auth/FirebaseAuth";
import {
  selectAccountSubscriptions,
  selectAccountSubscriptionsDataStatus
} from "../../selectors/account/account-subscriptions.selectors";
import type {LoadAccountSubscriptionAction} from "../../actions/account/account-subscriptions.actions";
import {
  completeSubscriptionCheckout,
  CompleteSubscriptionCheckoutAction,
  loadAccountSubscription,
  loadAccountSubscriptions,
  LoadAccountSubscriptionsAction
} from "../../actions/account/account-subscriptions.actions";


const AccountSubscriptionsManager = {

  init: () => {
    firebaseAuth.addAuthStateListener((user) => {
      if (user) {
        AccountSubscriptionsManager.loadAccountSubscriptions();
      }
    });
  },

  loadAccountSubscriptions: (): LoadAccountSubscriptionsAction => {
    const dataStatus = selectAccountSubscriptionsDataStatus(store.getState());
    if (dataStatus !== DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return loadAccountSubscriptions();
    } else {
      const items = selectAccountSubscriptions(store.getState());
      return Promise.resolve({success: {items: Object.values(items)}});
    }
  },

  reloadAccountSubscriptions: (): LoadAccountSubscriptionsAction => {
    if (firebaseAuth.getCurrentUser()) {
      return loadAccountSubscriptions();
    }
  },

  reloadAccountSubscription: (subscriptionId: string): LoadAccountSubscriptionAction => {
    const dataStatus = selectAccountSubscriptionsDataStatus(store.getState());
    if (subscriptionId && dataStatus === DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return loadAccountSubscription(subscriptionId);
    }
  },

  completeSubscriptionCheckout: (subscriptionId: string): CompleteSubscriptionCheckoutAction => {
    if (firebaseAuth.getCurrentUser()) {
      return completeSubscriptionCheckout(subscriptionId);
    }
  }

}


export default AccountSubscriptionsManager;
