import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";

export const configureFirebase = () => {
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
  })
}

export default firebase;
