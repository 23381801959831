import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import isDate from "lodash/isDate";
import dfnsFormatDistanceToNow from "date-fns/formatDistanceToNow";
import format from "date-fns/format";


export const DATE_FNS_LOCALES = {
  fr: fr,
  en: en
}

export const DATE_FORMAT_DATETIME_WORDS = "d MMMM yyyy '-' HH:mm";
export const DATE_FORMAT_DATE_WORDS = "d MMMM yyyy";

export const formatDate = (date: string | Date, pattern: string, options) => {
  if (!date) return;
  const d = isDate(date) ? date : new Date(date);
  return format(d, pattern, options);

}
export const formatDistanceToNow = (date: string | Date, options) => {
  if (!date) return;
  const d = isDate(date) ? date : new Date(date);
  return dfnsFormatDistanceToNow(d, options)
}
