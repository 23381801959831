import React from "react";
import FirebaseAuthContext , {AuthState}  from "../FirebaseAuthContext";
import {renderAndAddProps} from "render-and-add-props";

const IfFirebaseAuthed = ({children}) => {
  return (
    <FirebaseAuthContext.Consumer>
      {(authState: AuthState) => {
        return authState.isSignedIn
          ? renderAndAddProps(children)
          : null;
      }}
    </FirebaseAuthContext.Consumer>
  );
};


export default IfFirebaseAuthed;