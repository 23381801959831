import React, {Component} from "react";
import get from "lodash/get";
import pick from "lodash/pick";
import FirebaseAuthContext, {AuthState} from "./FirebaseAuthContext";
import {renderAndAddProps} from "render-and-add-props";


export type Props = {
  authDomain: string;
  apiKey: string;
  databaseURL: string;
  firebase: any;
  projectId: string;
  messagingSenderId?: string;
  storageBucket?: string;
}


export default class FirebaseAuthProvider extends Component<Props, AuthState> {

  state = {
    isSignedIn: false,
    providerId: null,
    user: null,
    firebase: {}
  };

  constructor(props: Props) {
    super(props);
    if (props.apiKey) {
      this.initializeFirebaseApp(Object.assign({}, props));
    }
  }

  initializeFirebaseApp = () => {
    const {firebase} = this.props;
    try {
      const conf = pick(this.props, ["apiKey", "authDomain", "databaseURL", "projectId", "storageBucket", "messagingSenderId"]);
      firebase.initializeApp(conf);
    } catch (err) {
      if (err.code !== "app/duplicate-app") throw err;
      console.warn("App has already been initialized, make sure you're not rendering FirebaseAuthProvider twice. If this is intended, you can safely ignore.");
    }
  }

  componentDidMount() {
    const {firebase} = this.props;
    this.stopListeningToAuth = firebase.app().auth().onAuthStateChanged(this.handleAuthChange)
  }

  componentWillUnmount() {
    this.stopListeningToAuth && this.stopListeningToAuth();
  }

  handleAuthChange = (user: any) => {
    const state = this.deriveStateFromUser(user);
    if (state !== null) {
      this.setState({...state});
    } else {
      console.error("Something unexpected happened with ", user);
    }
  }

  deriveStateFromUser = (user): AuthState => {
    const {firebase} = this.props;
    if (user === null) {
      return {isSignedIn: false, providerId: "none", user, firebase};
    } else if (user.isAnonymous === true) {
      return {isSignedIn: true, providerId: "anonymous", user, firebase};
    } else if (user.providerData) {
      return {isSignedIn: true, providerId: get(user, "providerData.0.providerId", "unknown"), user, firebase};
    }
  }

  render() {
    const {children} = this.props;
    return (
      <FirebaseAuthContext.Provider value={this.state}>
        {renderAndAddProps(children, {})}
      </FirebaseAuthContext.Provider>
    );
  }
}


