import React from 'react';
import {BackBox} from "amn/ui/containers/Boxes";
import Box from "@material-ui/core/Box";
import CopyToClipboardButton from "amn/ui/diverse/CopyToClipboardButton";
import {hideCharacters} from "amn/common/utils/StringUtils";
import WithBreakpoints from "../../../../../ui/containers/WithBreakpoints";

const AccountLicenseActivationToken = ({activationToken, secret = false, onCopy}) => (
  <WithBreakpoints>
    {(bps) =>
      (
  <BackBox display="flex" pl={2} pr={2} height={56}>

    <BackBox flex={1} pl={bps.mdUp? 2 : 0}
             justifyContent="center"
             display="flex"
             color="secondary.main"
             fontWeight="fontWeightMedium"
             alignItems="center">
      {bps.mdUp ?  (secret? hideCharacters(activationToken) : activationToken)  : "xxx"}

    </BackBox>
    <Box display="flex"
         ml={1}
         alignItems="center">
      <CopyToClipboardButton color="secondary"
                             anchorOrigin={{
                               vertical: 'bottom',
                               horizontal: 'center',
                             }}
                             onClick={onCopy}
                             size="small"
                             successMessage="Le code d'activation a été copié"
                             value={activationToken}/>
    </Box>

  </BackBox>
        )}</WithBreakpoints>
);

export default AccountLicenseActivationToken;