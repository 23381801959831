import React, {useState} from 'react'
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {IconButton} from "@material-ui/core";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import makeStyles from "@material-ui/core/styles/makeStyles";
import I18n from "../../../../../ui/i18n/I18n";
import {I18N_NS_BRANDING} from "../../../../../common/i18n/I18nextConfig";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ButtonSignIn from "./ButtonSignIn";

const useStyles = makeStyles({
  list: {
    width: 250
  }
});
export default function DrawerAppBar() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = React.useState(false);


  const handleClick = () => {
    setOpen(!open);
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  return (
    <div>
      <Drawer
        anchor='left'
        onClose={() => setOpenDrawer(false)} open={openDrawer}>

        <List className={classes.list}>
          <ListItem divider>
            <Box mb={1}>
              <ButtonSignIn/>
            </Box>
          </ListItem>
          <ListItemLink href="https://amnbrains.com/" target="_blank">
            <ListItemText>
              <Typography color="secondary" variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="companyName">
                  Amn Brains
                </I18n>
              </Typography>
            </ListItemText>
          </ListItemLink>
          <ListItemLink href="https://amnbrains.com/#solutions" target="_blank">
            <ListItemText>
              <Typography color="secondary" variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="solutions">
                  Solutions
                </I18n>
              </Typography>
            </ListItemText>
          </ListItemLink>
          <ListItemLink href="https://amnbrains.com/blog" target="_blank">
            <ListItemText>
              <Typography color="secondary" variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="blog">
                  Blog
                </I18n>
              </Typography>
            </ListItemText>
          </ListItemLink>

        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuOutlinedIcon/>
      </IconButton>
    </div>
  )


}
