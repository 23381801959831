import produce from "immer";

import type {Reducer} from "../common";
import {DATA_INITIAL, DATA_LOADED, SingleDataState} from "../common";
import type {CustomerInfo} from "amn/model/account/Subscription";
import {GET_CUSTOMER_INFO_SUCCESS} from "../../actions/account/account-customer-info.actions";

export type CustomerInfoState = SingleDataState<CustomerInfo>

const INITIAL_STATE: CustomerInfoState = {
  entity: {},
  status: DATA_INITIAL,
}


const customerInfo: Reducer = produce((draft: CustomerInfoState, action) => {
  switch (action.type) {


    case GET_CUSTOMER_INFO_SUCCESS: {
      if (action.success) {
        draft.entity = action.success;
        draft.status = DATA_LOADED;
      }
      break;
    }


    default:
      break;
  }
}, INITIAL_STATE);


export default customerInfo;
