import type {ListResponse} from "amn/model/common/Responses";
import type {License} from "../../model/account/Subscription";
import LicensesApi from "../api/portal/LicensesApi";


const LicensesBackendAdapter = {

  loadAccountLicenses: (): Promise<ListResponse<License>> => {
    return LicensesApi.getAccountLicenses();
  },

  patchLicense: (licenseId: string, patch: Partial<License>): Promise<ListResponse<License>> => {
    return LicensesApi.patchLicense(licenseId, patch);
  },

  sendLicenseInstructions: (licenseId: string, email: string): Promise<void> => {
    return LicensesApi.sendLicenseInstructions(licenseId, email);
  },


}

export default LicensesBackendAdapter;
