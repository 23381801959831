import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import React from "react";
import clsx from "clsx";



export const WhiteBorderedBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const WhiteBorderedCircleBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "50%"
  },
}))(Box);

export const WhiteBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const BackBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const BackCircleBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%"
  },
}))(Box);

export const BackBorderedBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}))(Box);

export const BorderedBox = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}))(Box);

export const ToolbarBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}))(Box);

export const HoverBox = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
  },
}))(Box);

export const PointerBox = withStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
}))(Box);

export const SelectableWhiteBox = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: "solid",
    '&:hover': {
      borderColor: theme.palette.divider,
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
    },
    '&.selected:hover': {
      borderColor: theme.palette.primary.main,
    },
  },

}))(React.forwardRef(({selected, ...props}, ref) => (
  <Box ref={ref}
       {...props}
       className={clsx({selected})}/>
)));

export const HoverBorderedBox = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
}))(Box);


export const stopPropagation = (e) => e.stopPropagation();
