import {mainColor} from "amn/modules/theme/theme";
import {Auth0LockConfiguration} from "amn/common/auth0/Auth0Lock.types";
import merge from "lodash/merge";

export const AUTH0_LOCK_DEFAULT_CONFIG = {
  configurationBaseUrl: 'https://cdn.eu.auth0.com',
  autofocus: true,
  avatar: null,
  language: "fr",
  mustAcceptTerms: true,

  auth: {
    redirect: false,
    redirectUrl: window.location.origin,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile email'
    },
  },
  theme: {
    logo: require("assets/images/logo/amn-brains-flat.png"),
    primaryColor: mainColor
  },
  languageDictionary: {
    forgotPasswordSubmitLabel: "Envoyer",
    title: "Se connecter",
    emailInputPlaceholder: "Email *",
    passwordInputPlaceholder: "Mot de passe *",
    signUpTerms: 'J’accepte <a target="_blank" href="https://amnbrains.com/privacy-policy">la politique de confidentialité</a>',
  },
  additionalSignUpFields: [
    {
      name: "family_name",
      placeholder: "Nom *",
      storage: "root",
      validator: function (name) {
        return {
          valid: name.length >= 1,
          hint: "Ne peut être vide"
        };
      }
    },
    {
      name: "given_name",
      placeholder: "Prénom *",
      storage: "root",
      validator: function (name) {
        return {
          valid: name.length >= 1,
          hint: "Ne peut être vide"
        };
      }
    },
    {
      name: "organization",
      placeholder: "Société",
      validator: function () {
        return true
      }
    },
    {
      name: "phone_number",
      placeholder: "Numéro de téléphone",
      storage: "root",
      validator: function (name) {
        return {
          valid: true,// todo validate phone number
          hint: "Numero de téléphone invalide"
        };
      }
    }
  ]
};


export const getAuth0LockConfig = (config: Partial<Auth0LockConfiguration> = {}): Auth0LockConfiguration => {
  return merge(AUTH0_LOCK_DEFAULT_CONFIG, config)
}