

export const Tutorials = [
  {
    id: "AZ9qYi9M5Z0",
    title: "01 - Première prise en main d'Amn Auditor",
    thumbnailUrl: "https://i.ytimg.com/vi/AZ9qYi9M5Z0/hqdefault.jpg"
  },
  {
    id: "Dssm0BLN7G0",
    title: "02 - Parcours d'audit - Présentation rapide",
    thumbnailUrl: "https://i.ytimg.com/vi/Dssm0BLN7G0/hqdefault.jpg"
  },
  {
    id: "lZyXUAZPvhI",
    title: "03 - Parcours d'audit - Navigation",
    thumbnailUrl: "https://i.ytimg.com/vi/Dssm0BLN7G0/hqdefault.jpg"
  },
  {
    id: "1B1bek2oloM",
    title: "04 - Parcours d'audit - Indicateurs et accès rapides",
    thumbnailUrl: "https://i.ytimg.com/vi/Dssm0BLN7G0/hqdefault.jpg"
  },
  {
    id: "HAm89v-WbcE",
    title: "05 - Mutualisation des écarts entre exigences",
    thumbnailUrl: "https://i.ytimg.com/vi/Dssm0BLN7G0/hqdefault.jpg"
  },
  {
    id: "wOwAsJygzow",
    title: "06 - Tableau de Bord",
    thumbnailUrl: "https://i.ytimg.com/vi/Dssm0BLN7G0/hqdefault.jpg"
  }
]