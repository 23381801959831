import i18n from "i18next";
import {I18N_EN_LOCALE, I18N_FR_LOCALE} from "amn/common/Constants";
import {initReactI18next} from "react-i18next";


import LanguageDetector from 'i18next-browser-languagedetector';

import translation_en from "./locales/en/translation.json";
import translation_fr from "./locales/fr/translation.json";

import portal_en from "./locales/en/portal.json";
import portal_fr from "./locales/fr/portal.json";

import branding_en from "./locales/en/branding.json";
import branding_fr from "./locales/fr/branding.json";

import iam_en from "./locales/en/iam.json";
import iam_fr from "./locales/fr/iam.json";

export const I18N_NS_BRANDING = "branding";
export const I18N_NS_STANDARDS = "standards";
export const I18N_NS_PORTAL = "portal";
export const I18N_NS_IAM = "iam";


const i18nextOptions = {
  fallbackLng: I18N_EN_LOCALE,
  whitelist: [I18N_EN_LOCALE, I18N_FR_LOCALE],
  resources: {
    en: {
      translation: translation_en,
      portal: portal_en,
      branding: branding_en,
      iam: iam_en
    },
    fr: {
      translation: translation_fr,
      portal: portal_fr,
      branding: branding_fr,
      iam: iam_fr
    }
  },
  interpolation: {
    escapeValue: false
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', "ul", "li"],
  }
};

i18n.use(LanguageDetector)
  .use(initReactI18next);

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(i18nextOptions);
}

export default i18n;


export const changeLanguage = (language: I18N_EN_LOCALE | I18N_FR_LOCALE): Promise => {
  return i18n.changeLanguage(language);
}

export const getCurrentLanguage = (): string => {
  return i18n.language;
}
