import produce from "immer";

import type {ListDataState, Reducer} from "../common";
import {DATA_ERROR, DATA_INITIAL, DATA_LOADED, DATA_LOADING} from "../common";
import {listToLookupTable} from "amn/model/common/Divers";
import type {CreateSubscriptionResponse, License} from "amn/model/account/Subscription";
import {
  LOAD_ACCOUNT_LICENSES_ERROR,
  LOAD_ACCOUNT_LICENSES_REQUEST,
  LOAD_ACCOUNT_LICENSES_SUCCESS,
  PATCH_LICENSE_SUCCESS
} from "../../actions/account/account-licenses.actions";
import {
  COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_SUCCESS,
  CREATE_ACCOUNT_SUBSCRIPTION_SUCCESS
} from "../../actions/account/account-subscriptions.actions";
import {ActionPromisePayload} from "../../actions/common";

export type AccountLicensesState = ListDataState<License>

const INITIAL_STATE: AccountLicensesState = {
  entities: {},
  status: DATA_INITIAL,
}


const accountLicenses: Reducer = produce((draft: AccountLicensesState , action) => {
  switch (action.type) {


    ///******************************
    //

    case LOAD_ACCOUNT_LICENSES_REQUEST: {
      draft.entities = {};
      draft.status = DATA_LOADING;
      break;
    }

    case LOAD_ACCOUNT_LICENSES_SUCCESS: {
      if (action.success) {
        draft.entities = listToLookupTable(action.success.items);
        draft.status = DATA_LOADED;
      }
      break;
    }

    case LOAD_ACCOUNT_LICENSES_ERROR: {
      draft.status = DATA_ERROR;
      break;
    }

    ///******************************
    //

    case PATCH_LICENSE_SUCCESS: {
      draft.entities[action.request.licenseId] = action.success;
      break;
    }


    ///******************************
    //

    case COMPLETE_ACCOUNT_SUBSCRIPTION_CHECKOUT_SUCCESS:
    case CREATE_ACCOUNT_SUBSCRIPTION_SUCCESS: {
      const payload: ActionPromisePayload<{}, CreateSubscriptionResponse> = action;
      if (payload.success?.license) {
        draft.entities[payload.success?.license.id] = payload.success?.license;
        draft.status = DATA_LOADED;
      }
      break;
    }

    default:
      break;
  }
}, INITIAL_STATE);


export default accountLicenses;
