import get from "lodash/get";
import {createSelector} from 'reselect';
import type {LookupTable} from "amn/model/common/Divers";
import {LookupFn} from "amn/model/common/Divers";
import type {DataStatus} from "amn/store/reducers/common";
import type {License, Subscription} from "amn/model/account/Subscription";
import memoize from "lodash/memoize";
import {makeAccountSubscriptionSelector} from "./account-subscriptions.selectors";
import type {GenericG2Datum} from "../../../ui/graph/GenericG2Data";
import map from "lodash/map";
import countBy from "lodash/countBy";

export const selectAccountLicensesState = (state) => get(state, 'account.licenses');

export const selectAccountLicenses = createSelector(
  selectAccountLicensesState,
  (state): LookupTable<License> => get(state, 'entities', {})
);

export const selectAccountLicensesDataStatus = createSelector(
  selectAccountLicensesState,
  (state): DataStatus => get(state, 'status')
);

export const makeAccountLicenseSelector = createSelector(
  selectAccountLicenses,
  (licenses: LookupTable<License> = {}): LookupFn<License> => memoize(
    (id) => licenses[id]
  )
);


export const selectAccountLicenseStatus = createSelector(
  makeAccountLicenseSelector,
  makeAccountSubscriptionSelector,
  (getLicense, getSubscription): LookupFn<License> => memoize(
    (id) => {
      const license: License = getLicense(id);
      const subscription: Subscription = getSubscription(license?.subscriptionId);
      if (subscription) {
        return subscription.status;
      } else {

      }
    }
  )
);


export const selectLicensesStatusRepartition = createSelector(
  selectAccountLicenses,
  selectAccountLicenseStatus,
  (licenses: LookupTable<License>, getLicenseStatus): GenericG2Datum[] => {
    const rep = countBy(licenses, l => getLicenseStatus(l.id))
    return map(rep, (value, key) => {
      return {
        key,
        value
      }
    })
  }
)