export type SidebarClassKey =
  | 'root'
  | 'selected'
  | 'focusVisible'
  | 'collapsed'
  | 'dot';

export type SidebarItemStyleProps = {
  color: string;
};

export default (theme) => {
  return {
    root: {
      fontFamily: "inherit",
      color: theme.palette.secondary.main,
      display: 'flex',
      marginBottom: 8,
      width: '100%',
      justifyContent: 'flex-start',
      // borderRadius: 3,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 20,
      minHeight: 40,
      textAlign: "left",
      transition: '0.2s',
      '-webkit-font-smoothing': 'antialiased',
      padding: '0 12px 0 26px',
      fontSize: 14,
      '& .SidebarItem-label': {
        whiteSpace: 'nowrap',
        width:"100%",
        fontWeight:500,
        visibility: "visible",
        opacity: 1,
        transition: "visibility 0s, opacity 0.4s linear"

      },
      '&:hover, &$focusVisible': {
        backgroundColor: 'rgba(0,0,0,0.04)',
      },
      '& .SidebarItem-startIcon': {
        display: 'inline-flex',
        // opacity: 0.72,
        marginRight: 18,
        '& > *': {
          fontSize: 20,
        },
      },
      '& .SidebarItem-amount': {
        fontSize: '0.75rem',
        letterSpacing: 0.3,
        marginLeft: 'auto',
        paddingLeft: 16,
      },
    },
    selected: ({color}: SidebarItemStyleProps) => ({
      fontWeight: 'bold',
      color: color?.main || theme.palette.secondary.main,
      // backgroundColor:"rgb(255, 231, 229) !important",
      backgroundColor: color?.bg || theme.palette.secondary.bg,

      '& .SidebarItem-startIcon': {
        opacity: 1,
      },
    }),
    collapsed: ({color}: SidebarItemStyleProps) => ({
      padding: '0 6px 0 10px',
      width: 40,
      height: 40,
      borderRadius: 20,
      marginLeft: 16,
      '& .SidebarItem-label': {
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 0s, opacity 0.4s linear"
      },
      '& .SidebarItem-amount': {
        position: 'absolute',
        top: 0,
        right: -8,
        backgroundColor: color,
        color: '#fff',
        padding: '0 4px',
        borderRadius: 10,
        fontWeight: 'normal',
      }
    }),
    dot: {
      '& .SidebarItem-amount': {
        display: 'none',
      },
      '&:after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        width: 8,
        height: 8,
        backgroundColor: '#da3125',
        top: 4,
        right: -2,
        borderRadius: '50%',
      }
    },
    focusVisible: {},
  };
};
