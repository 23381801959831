import React, {Component} from 'react';
import {createAccountSubscription} from "amn/store/actions/account/account-subscriptions.actions";
import isEmpty from "lodash/isEmpty";
import YouTube from 'react-youtube';
import type {ErrorItem} from "amn/model/common/Responses";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LaptopChromebookOutlinedIcon from "@material-ui/icons/LaptopChromebookOutlined";
import ContractIcon from "amn/ui/icons/ContractIcon";
import SubscriptionCanceledIcon from "amn/ui/icons/SubscriptionCanceledIcon";
import ProjectsIcon from "amn/ui/icons/ProjectsIcon";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import type {License} from "amn/model/account/Subscription";
import {
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY,
  SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY
} from "amn/model/account/Subscription";
import CircularProgress from "@material-ui/core/CircularProgress";
import SuccessIcon from "amn/ui/images/SuccessIcon";
import Typography from "@material-ui/core/Typography";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Config from "amn/Config";
import Link from "@material-ui/core/Link";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import AccountLicenseActivationToken from "../../../account/components/licenses/AccountLicenseActivationToken";
import {NavLink} from "react-router-dom";
import PortalPaths from "../../../../common/routes/PortalPaths";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {withSnackbar} from "notistack";
import {withTranslation} from "react-i18next";
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {BackBox} from "../../../../../ui/containers/Boxes";
import Hidden from "@material-ui/core/Hidden";

type Props = {};
const STEP_PLAN = "PLAN";
const STEP_PROCESSING = "PROCESSING";
const STEP_SUCCESS = "SUCCESS";
const STEP_DOWNLOAD = "DOWNLOAD";
const STEP_ACTIVATION = "ACTIVATION";
const STEP_GUIDE = "GUIDE";


const STEPS = {
  [STEP_PLAN]: {name: STEP_PLAN, order: 1, title: "Sélectionner un plan"},
  [STEP_PROCESSING]: {name: STEP_PROCESSING, order: 2, title: "Création de la licence"},
  [STEP_SUCCESS]: {name: STEP_SUCCESS, order: 3, title: "Création de la licence"},
  [STEP_DOWNLOAD]: {name: STEP_DOWNLOAD, order: 4, title: "Télécharger l'application"},
  [STEP_ACTIVATION]: {name: STEP_ACTIVATION, order: 5, title: "Activer l'application"},
  [STEP_GUIDE]: {name: STEP_GUIDE, order: 6, title: "Première prise en main"},
}

type State = {
  activeStep: STEP_PLAN | STEP_PROCESSING | STEP_SUCCESS | STEP_DOWNLOAD | STEP_ACTIVATION;
  createdLicense?: License;
};

class AmnAuditorTrialWizard extends Component<Props, State> {

  constructor(props) {
    super(props);
    const {plan} = props;
    this.state = {
      createdLicense: null,
      activeStep: plan ? STEP_PROCESSING : STEP_PLAN
    }
  }


  componentDidMount() {
    this.start();
  }

  start = async () => {
    const {plan} = this.props;
    if (plan) {
      await this.startTrial(plan);
    }
  }

  startTrial = async (plan) => {
    try {
      this.setState({activeStep: STEP_PROCESSING});
      const result = await createAccountSubscription(plan);
      if (result.success.license) {
        // this.props.history.push(PortalPaths.amnAuditorLicenseCreationSuccessUrl(result.success?.license?.id));
        this.setState({activeStep: STEP_SUCCESS, createdLicense: result.success.license});
      }
    } catch (ex) {
      const {enqueueSnackbar} = this.props;
      if (!isEmpty(ex?.error?.errors)) {
        const error: ErrorItem = ex.error.errors[0];
        enqueueSnackbar(error?.title, {variant: 'error'});
      } else {
        enqueueSnackbar("Une erreur est survenue de la création de votre license d'essai", {variant: 'error'});
      }
    }
  }


  render() {
    const {plan} = this.props;
    const {activeStep} = this.state;
    return (
      <>
        <Accordion expanded={activeStep === STEP_PLAN}>
          <AccordionSummary>
            {this.renderStepTitle(STEP_PLAN)}
          </AccordionSummary>
          <AccordionDetails>
            <Hidden smDown>
              <Box flex={1}/>
            </Hidden>
            <Box flex={3}>
              {this.renderPlanSelector()}
            </Box>
            <Hidden smDown>
              <Box flex={1}/>
            </Hidden>

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === STEP_PROCESSING || activeStep === STEP_SUCCESS}>
          <AccordionSummary>
            {this.renderStepTitle(STEP_PROCESSING)}
          </AccordionSummary>
          <AccordionDetails>
            <Box flex={1}/>
            <Box flex={3}>
              {activeStep === STEP_PROCESSING && this.renderProcessing()}
              {activeStep === STEP_SUCCESS && this.renderSuccess()}
            </Box>
            <Box flex={1}/>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === STEP_DOWNLOAD}>
          <AccordionSummary>
            {this.renderStepTitle(STEP_DOWNLOAD)}
          </AccordionSummary>
          <AccordionDetails>
            <Box flex={1}/>
            <Box flex={3}>
              {this.renderDownload()}
            </Box>
            <Box flex={1}/>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === STEP_ACTIVATION}>
          <AccordionSummary>
            {this.renderStepTitle(STEP_ACTIVATION)}
          </AccordionSummary>
          <AccordionDetails>
            <Box flex={1}>
              <Box>
                <Button color="secondary" onClick={() => this.setState({activeStep: STEP_DOWNLOAD})}
                        startIcon={<ChevronLeftOutlinedIcon/>}>Retour</Button>
              </Box>
            </Box>
            <Box flex={3}>
              {this.renderActivation()}
            </Box>
            <Box flex={1}/>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={activeStep === STEP_GUIDE}>
          <AccordionSummary>
            {this.renderStepTitle(STEP_GUIDE)}
          </AccordionSummary>
          <AccordionDetails>
            <Box flex={1}>
              <Box>
                <Button color="secondary" onClick={() => this.setState({activeStep: STEP_ACTIVATION})}
                        startIcon={<ChevronLeftOutlinedIcon/>}>Retour</Button>
              </Box>
            </Box>
            <Box flex={3}>
              {this.renderGuide()}
            </Box>
            <Box flex={1}/>
          </AccordionDetails>
        </Accordion>

      </>);
  }

  renderStepTitle = (step) => {
    const {activeStep} = this.state;
    const stepConfig = STEPS[step];
    const isActive = activeStep === step;
    const isSucceeded = STEPS[activeStep].order > stepConfig.order;
    const icon = isActive ? <PlaylistAddCheckOutlinedIcon/> : isSucceeded ? <BeenhereOutlinedIcon/> :
      <QueryBuilderOutlinedIcon/>;
    return (
      <Box p={1} display="flex" alignItems="center">
        <Box mr={1}
             color={isActive ? "primary.main" : isSucceeded ? "success.main" : "action.disabled"}
             display="flex" alignItems="center">
          {icon}
        </Box>
        <Box color={isActive ? "primary.main" : isSucceeded ? "secondary.main" : "action.disabled"}
             fontWeight="fontWeightMedium" fontSize="body1.fontSize">
          {stepConfig.title}
        </Box>
      </Box>
    )
  }

  renderPlanSelector = () => {
    return (
      <Box pb={4} mt={2}>
        <Box textAlign="center">
          <Box mb={3} color="primary.main" fontWeight={600} fontSize="h6.fontSize">Sélectionner un Plan</Box>
          <div>
            <img src={require("assets/images/icons/certificate.svg")}
                 style={{maxHeight: 88}}
                 alt="Amn Brains"/>
          </div>
          <div>
            <Box mt={1.5} fontSize="body1.fontSize" color="secondary.main" fontWeight={600}>
              1 Licence Amn Auditor
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
              <Box color="secondary.main" fontWeight="fontWeightMedium">

                {this.renderAdvantage("1 Application Desktop", LaptopChromebookOutlinedIcon)}

                {this.renderAdvantage("Activable sur 1 machine", ContractIcon)}

                {this.renderAdvantage("Résiliable à tout moment", SubscriptionCanceledIcon)}

                {this.renderAdvantage("Projets d'Audit illimités", ProjectsIcon)}

              </Box>
            </Box>

            <Box mt={4}>
              <Box color="secondary.main">
                <Box>
                  Essayez Amn Auditor <b>GRATUITEMENT</b>
                </Box>
                <Box mt={0.5}>
                  pendant
                  &nbsp;<Box display="inline" color="primary.main" fontSize="h6.fontSize"><b>14 jours</b></Box>
                </Box>
              </Box>
              <Box mt={1} display="flex" justifyContent="center">
                <Box display="flex" color="text.secondary" alignItems="center">
                  <Box display="flex" alignItems="center" mr={1}>
                    {/*<Icon fontSize="small" color="primary"/>*/}
                    <CreditCardOutlinedIcon fontSize="small"/>
                  </Box>
                  <Box fontSize="caption.fontSize">
                    Sans Carte Bancaire
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width="90%"
                 ml="auto"
                 mr="auto"
                 pb={3}
                 mt={4}
                 color="secondary.main">
              <Box display="flex">
                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <b>
                    Plan Annuel
                  </b>
                </Box>

                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <b>
                    Plan Mensuel
                  </b>
                </Box>
              </Box>


              <Box mt={3} display="flex">
                <Box flex={1}
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                  <Box fontSize="h4.fontSize">
                    <b>499</b>
                  </Box>
                  <Box fontSize="subtitle1.fontSize" position="relative">

                    <Box position="absolute"
                         fontSize="body1.fontSize"
                         fontWeight="fontWeightBold"
                         color="primary.main"
                         p={0.5}
                         bgcolor="success.bg"
                         right={-48}
                         top={-28}>
                      -20%
                    </Box>
                    <b>,99&nbsp;€ HT</b>
                  </Box>
                </Box>

                <Box flex={1}
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                  <Box fontSize="h4.fontSize">
                    <b>59</b>
                  </Box>
                  <Box fontSize="subtitle1.fontSize">
                    <b>,99&nbsp;€ HT</b>
                  </Box>
                </Box>
              </Box>

              <Box mt={3} display="flex">
                <Box flex={1}
                     textAlign="center">
                  <Button color="primary"
                          onClick={() => this.startTrial(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_YEARLY)}
                          variant="contained">
                    Démarrer l'essai
                  </Button>
                </Box>

                <Box flex={1}
                     fontSize="h6.fontSize"
                     textAlign="center">
                  <Button color="secondary"
                          onClick={() => this.startTrial(SUBSCRIPTION_PLAN_AMN_AUDITOR_BASIC_MONTHLY)}
                          variant="outlined">
                    Démarrer l'essai
                  </Button>
                </Box>
              </Box>

            </Box>
          </div>
        </Box>
      </Box>
    )
  }

  renderAdvantage(title, Icon) {
    return (
      <Box mt={2} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={2}>
          <Icon fontSize="small" color="primary"/>
        </Box>
        <Box fontSize="body2.fontSize" display="flex" alignItems="center">
          {title}
        </Box>
      </Box>
    );
  }

  renderProcessing = () => {
    return (
      <Box mt={4} minHeight={160} textAlign="center">
        <Box>
          Nous préparons votre licence d'essai
        </Box>
        <Box mt={6} pb={4}>
          <CircularProgress/>
        </Box>
      </Box>
    )
  }

  renderSuccess = () => {
    return (
      <Box mt={4} textAlign="center">
        <Box mb={1}>
          <SuccessIcon width={80} height={80}/>
        </Box>
        <div>
          <Typography variant="subtitle2" color="secondary">
            Votre license a été créée avec succès
          </Typography>
        </div>
        <Box mt={3} pl={4} pr={4}>
          <Typography variant="subtitle1" color="secondary">
            Pour commencer à utiliser Amn Auditor, suivez les étapes suivantes !
          </Typography>
        </Box>
        <Box mt={6} mb={4} display="flex" justifyContent="center">
          <Button color="primary"
                  endIcon={<ChevronRightOutlinedIcon/>}
                  onClick={() => this.setState({activeStep: STEP_DOWNLOAD})}
                  variant="contained">
            Commencer à utiliser ma licence
          </Button>
        </Box>
      </Box>
    )
  }

  renderDownload = () => {
    return (
      <Box>

        <Box mt={2} mb={2} p={4} pl={8} pr={8}>

          <Box mt={3} textAlign="center" alignItems="flex-end" flexDirection="column">
            <img src={require("assets/images/logo/amn-auditor.svg")}
                 style={{maxHeight: 60, minWidth: 120}}
                 alt="Amn Brains"/>

          </Box>
          <Box mt={3} color="secondary.main">
            <Box textAlign="center" fontWeight="fontWeightMedium">
              <Box color="primary.main" fontSize="h6.fontSize">Télécharger et Installer</Box>

              <Box mt={2}>
                Commencer d'abord par Télécharger et Installer l'application sur la machine que vous aimeriez utiliser.
              </Box>
            </Box>
            <Box mt={4}>

              <Alert severity="warning">
                <AlertTitle>Attention</AlertTitle>
                Une licence est activable sur une seule machine. Il est donc important d'installer
                l'application sur la machine sur laquelle vous allez activer votre licence.
              </Alert>
            </Box>
          </Box>

          <Box textAlign="center" mt={6} mb={2}>

            <Button startIcon={<GetAppOutlinedIcon/>}
                    variant="contained"
                    component="a"
                    href={Config.AMN_AUDITOR.INSTALLER_LINK}
                    target="_blank"
                    color="secondary">
              Télécharger l'application Windows
            </Button>
            <Box mt={1}
                 fontSize="caption.fontSize"
                 display="flex"
                 color="secondary.main"
                 justifyContent="center"
                 alignItems="center">
              <Link startIcon={<GetAppOutlinedIcon/>}
                    component="a"
                    href={Config.AMN_AUDITOR.PORTABLE_LINK}
                    target="_blank"
                    color="primary">
                Télécharger la version portable
              </Link>
            </Box>
          </Box>

        </Box>
        <Box mb={4} display="flex" justifyContent="center">
          <Button color="primary"
                  onClick={() => this.setState({activeStep: STEP_ACTIVATION})}
                  endIcon={<ChevronRightOutlinedIcon/>}
                  variant="contained">
            Suivant
          </Button>
        </Box>
      </Box>
    )
  }

  renderActivation = () => {
    const {createdLicense} = this.state;
    const activationToken = createdLicense?.activationToken;
    return (
      <>

        <Box textAlign="center" mt={2} p={2}>

          <Box mt={3} alignItems="flex-end" flexDirection="column">

            <img src={require("assets/images/icons/activation.svg")}
                 alt="License Activation"/>

          </Box>

          <Box mt={3} color="secondary.main">
            <Box color="primary.main" fontWeight="fontWeightMedium" fontSize="h6.fontSize">Activer la licence
              d'essai</Box>

            <Box mt={2}>
              Une fois l'application est installée et démarrée, <br/>
              utiliser le code d'activation ci-dessous pour activer l'application sur votre machine.
            </Box>
            <Box mt={2}>
              <Box display="inline-block" bgcolor="info.bg">
                Ce code d'activation reste accessible à tout moment depuis votre espace en ligne.
              </Box>
            </Box>
          </Box>

          <Box mt={4} mb={1}>
            <Box mb={1} fontWeight="fontWeightMedium" color="secondary.main">
              Code d'activation de la licence
            </Box>
            <AccountLicenseActivationToken activationToken={activationToken}/>
          </Box>

          <Box mt={4} mb={4}>
            <Button onClick={() => this.setState({activeStep: STEP_GUIDE})}
                    endIcon={<ChevronRightOutlinedIcon/>}
                    color="primary"
                    variant="contained">
              Suivant
            </Button>
          </Box>

        </Box>
      </>
    )
  }

  renderGuide = () => {

    return (
      <Box textAlign="center" mt={2} p={2}>


        <Box mt={3} color="secondary.main">
          <Box color="primary.main" fontWeight="fontWeightMedium" fontSize="h6.fontSize">Première prise en main</Box>

          <Box mt={2}>
            La vidéo ci-dessous vous aidera à bien débuter avec Amn Auditor.
          </Box>
          <Box mt={4} alignItems="flex-end" flexDirection="column">

            <YouTube videoId="AZ9qYi9M5Z0" opts={{
              height: '390',
              width: '640',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                color: "#109c1",
              },
            }}/>

            {/*<a href="https://amnbrains.com/amn-auditor-early-adopters-download#videos" target="_blank">*/}
            {/*  <img src={require("assets/images/others/tutorials.png")}*/}
            {/*       style={{maxWidth: "72%"}}*/}
            {/*       alt="Amn Auditor Tutorial"/>*/}
            {/*</a>*/}
          </Box>

          <Box mt={2} textAlign="center">
            <a href="https://amnbrains.com/amn-auditor-early-adopters-download#videos" target="_blank">
              Cliquer ici pour accéder à l'ensemble des guides et tutoriels vidéos.
            </a>
          </Box>
        </Box>

        <Box mt={5}>
          Si vous avez besoin d'aide, ou vous avez des questions ou des demandes particulières, n'hésitez pas à nous
          contacter sur
        </Box>
        <BackBox p={2} mt={2}>
          <a href="mailto:support@amnbrains.com">
            <Box component="span" color="primary.main">
              support@amnbrains.com
            </Box>
          </a>
        </BackBox>


        <Box mt={4} mb={4}>
          <Button component={NavLink}
                  to={PortalPaths.accountLicensesUrl()}
                  color="primary"
                  variant="contained">Aller à mon compte</Button>
        </Box>

      </Box>
    )
  }
}

export default withSnackbar(
  withTranslation(I18N_NS_STANDARDS)(
    AmnAuditorTrialWizard
  )
);