import React, {Component} from 'react';
import {connect} from "react-redux";
import type {DataStatus} from "amn/store/reducers/common";
import {DATA_INITIAL, DATA_LOADING} from "amn/store/reducers/common";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import type {LookupTable} from "amn/model/common/Divers";
import type {License, SubscriptionPlan} from "amn/model/account/Subscription";
import {
  selectAccountLicenses,
  selectAccountLicensesDataStatus
} from "amn/store/selectors/account/account-licenses.selectors";
import isEmpty from "lodash/isEmpty";
import AmnAuditorStartTrialContent from "./AmnAuditorStartTrialContent";

type Props = {
  plan: SubscriptionPlan;
  dataStatus: DataStatus;
  licenses: LookupTable<License>;
};

type State = {};


class AmnAuditorStartTrial extends Component<Props, State> {


  render() {
    const {dataStatus, licenses, plan} = this.props;
    return (
      <LoadingContainer loading={dataStatus === DATA_LOADING || dataStatus === DATA_INITIAL}>
        <AmnAuditorStartTrialContent plan={plan} canStartTrial={isEmpty(licenses)}/>
      </LoadingContainer>
    );
  }


  static mapStateToProps = (state) => {
    return {
      dataStatus: selectAccountLicensesDataStatus(state),
      licenses: selectAccountLicenses(state),
    }
  };
}


export default connect(AmnAuditorStartTrial.mapStateToProps)(AmnAuditorStartTrial);

