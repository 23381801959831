import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import PrimaryLayout from "../../../common/components/layout/primary/PrimaryLayout";
import AmnAuditorStartTrial from "../components/trial/AmnAuditorStartTrial";
import {SUBSCRIPTION_PLAN_AMN_AUDITOR_PLANS} from "../../../../model/account/Subscription";

class AmnAuditorSignupCompleteScreen extends Component {

  getPlan = () => {
    const {location} = this.props;
    const {search} = location;
    if (search) {
      const sp = new URLSearchParams(search);
      const plan = sp.get("p");
      if (SUBSCRIPTION_PLAN_AMN_AUDITOR_PLANS.includes(plan)) {
        return plan;
      }
    }
  }

  render() {
    return (
      <PrimaryLayout>
        <Box mt={4} mb={4}>
          <AmnAuditorStartTrial plan={this.getPlan()}/>
        </Box>
      </PrimaryLayout>
    );
  }
}

export default AmnAuditorSignupCompleteScreen;