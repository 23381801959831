import React from 'react';
import type {SubscriptionStatus} from "amn/model/account/Subscription";
import {getSubscriptionStatusLabel, getSubscriptionStatusOption} from "amn/model/account/Subscription";
import GenericOptionIcon from "amn/ui/forms/GenericOptionIcon";
import {SvgIconProps} from "@material-ui/core/SvgIcon";
import Box from "@material-ui/core/Box";
import I18nTooltip from "amn/ui/i18n/I18nTooltip";
import AccountSubscriptionStatusIcon from "./AccountSubscriptionStatusIcon";

type Props = {
  status: SubscriptionStatus;
  iconProps?: SvgIconProps;
};

const AccountSubscriptionStatus = ({status, iconProps}: Props) => {
  const option = getSubscriptionStatusOption(status);
  const label = getSubscriptionStatusLabel(status, "fr");
  return (
    <>
      {option &&
        <I18nTooltip i18nText={option.description}>
          <Box display="flex"
               p={1}
               // minWidth={160}
            // border={1}
            // borderColor={option.iconColor}
               bgcolor={option.bgColor}
               borderRadius={4}
               alignItems="center">
            <AccountSubscriptionStatusIcon status={status} fontSize="small" {...iconProps}/>
            <Box ml={0.5} mr={0.5} color={option.iconColor}>
              {label}
            </Box>
          </Box>
        </I18nTooltip>
      }
    </>
  );
};

export default AccountSubscriptionStatus;