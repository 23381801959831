import store from "amn/store";
import type {ActionPromise} from "../common";
import type {ListResponse} from "amn/model/common/Responses";
import LicensesBackendAdapter from "amn/backend/adapters/LicensesBackendAdapter";
import type {License} from "amn/model/account/Subscription";


export const LOAD_ACCOUNT_LICENSES_REQUEST = 'app/standards/LOAD_ACCOUNT_LICENSES_REQUEST';
export const LOAD_ACCOUNT_LICENSES_SUCCESS = 'app/standards/LOAD_ACCOUNT_LICENSES_SUCCESS';
export const LOAD_ACCOUNT_LICENSES_ERROR = 'app/standards/LOAD_ACCOUNT_LICENSES_ERROR';


export type LoadAccountLicensesAction = ActionPromise<{}, ListResponse<License>>;

export function loadAccountLicenses(): LoadAccountLicensesAction {
  return store.dispatch({
    types: [LOAD_ACCOUNT_LICENSES_REQUEST, LOAD_ACCOUNT_LICENSES_SUCCESS, LOAD_ACCOUNT_LICENSES_ERROR],
    promise: LicensesBackendAdapter.loadAccountLicenses()
  });
}


export const PATCH_LICENSE_REQUEST = 'app/standards/PATCH_LICENSE_REQUEST';
export const PATCH_LICENSE_SUCCESS = 'app/standards/PATCH_LICENSE_SUCCESS';
export const PATCH_LICENSE_ERROR = 'app/standards/PATCH_LICENSE_ERROR';


export type PatchLicenseAction = ActionPromise<{ licenseId: string, patch: Partial<License> }, License>;

export function patchLicense(licenseId: string, patch: Partial<License>): PatchLicenseAction {
  return store.dispatch({
    types: [PATCH_LICENSE_REQUEST, PATCH_LICENSE_SUCCESS, PATCH_LICENSE_ERROR],
    licenseId,
    patch,
    promise: LicensesBackendAdapter.patchLicense(licenseId, patch)
  });
}



